import BaseRequest from '../BaseRequest'

export default class CommonRequest extends BaseRequest {
  getStatistic (params) {
    return this.get(`statistic/new-ws-metrics`, params)
  }
  fetchLivestreamSchedule (params) {
    return this.get(`go/circle/schedule/list`, params)
  }
  createLivestreamSchedule (params) {
    return this.post(`go/circle/schedule`, params)
  }
  editLivestreamSchedule (id, params) {
    return this.put(`go/circle/schedule/` + id, params)
  }
  deleteLivestreamSchedule (id) {
    return this.delete(`go/circle/schedule/` + id)
  }
  getLiveStreamScheduleDetail (id) {
    return this.get(`go/circle/schedule/${id}`)
  }
}
