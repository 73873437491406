import BaseRequest from '../BaseRequest'

export default class EHCSyncRequest extends BaseRequest {
  ehcSyncPatients (params) {
    return this.get(`go/hodo-ehc/sync-patient`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncEmr (params) {
    return this.get(`go/hodo-ehc/sync-emr`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncTreatment (params) {
    return this.get(`go/hodo-ehc/sync-treatment`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncIndication (params) {
    return this.get(`go/hodo-ehc/sync-indication`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncHealthRecords (params) {
    return this.get(`go/hodo-ehc/sync-health-record`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncIndicationHealthRecords (params) {
    return this.get(`go/hodo-ehc/sync-indication-health-record`, params, null, {
      timeout: 300000
    })
  }
  ehcSyncCoreIndicationMedicalRecordById (id) {
    return this.post(
      `/go/hodo-hv/sync-core-indication-medical-record/${id}`,
      null,
      {
        timeout: 300000
      }
    )
  }
  ehcSyncCoreTreatmentMedicalRecord (data) {
    return this.post(`go/hodo-hv/sync-core-treatment-medical-record`, data, {
      timeout: 300000
    })
  }
  ehcSyncRawCoverMedicalRecord (data) {
    return this.post(`go/hodo-hv/sync-raw-cover-medical-record`, data, {
      timeout: 300000
    })
  }
}
