import BaseRequest from '../BaseRequest'
import qs from 'qs'

export default class DoctorRequest extends BaseRequest {
  updateCircleAttachment (id, params) {
    return this.put(`doctor/circle-attachment/${id}`, params)
  }
  getWorkspace (params) {
    return this.get(`go/clinics`, params)
  }
  getKycCerts (p) {
    return this.get(`doctor/kyc-certs`, p)
  }
  getCertRequires (p) {
    return this.get(`doctor/cert-requires`, p)
  }
  createKyc (data) {
    return this.post(`doctor/kyc-certs`, data)
  }
  updateKyc (id, data) {
    return this.post(`doctor/kyc-certs/${id}`, data)
  }
  getOrgStats (id) {
    return this.get(`doctor/organizations/${id}/statistic`)
  }
  getOrg (id) {
    return this.get(`doctor/organizations/` + id)
  }
  getOrgList () {
    return this.get(`doctor/organizations/`)
  }
  putOrgList (id, params) {
    return this.post(`doctor/organizations/${id}`, params)
  }
  getOrgWorkspace (id, params) {
    return this.get(`doctor/organizations/${id}/workspaces`, params)
  }
  getSpecialistsDetail (params) {
    return this.get(`doctor/doctor-specialists`, params)
  }
  UpdateSpecialist (params) {
    return this.post(`doctor/doctor-specialists`, params)
  }
  CreateSpecialists (params) {
    return this.post(`doctor/multi-specialists`, params)
  }
  getPersons (params) {
    return this.get(`doctor/persons`, params)
  }
  postPerson (params) {
    return this.post(`doctor/persons`, params)
  }
  getDoctorPersons (params) {
    return this.get(`doctor/doctor-persons`, params)
  }
  updatePersonInfo (id, params) {
    return this.put(`v2/doctor/persons/${id}`, params)
  }
  getPersonDetail (id) {
    return this.get('doctor/persons/' + id)
  }
  getDoctorMe () {
    return this.get(`doctor/me`)
  }

  updateDoctorMe (data) {
    return this.post(`doctor/me`, data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  addAppointment (data) {
    return this.post(`doctor/appointments`, data)
  }

  getAppointments (params) {
    return this.get(`doctor/appointments`, params)
  }
  getNotifications (data) {
    return this.get(`doctor/notifications`, data)
  }
  getNotificationDetail (id) {
    return this.get(`doctor/notifications/${id}`)
  }
  getDoctorStats () {
    return this.get(`doctor/stats`)
  }
  updateState (appt_id, params) {
    return this.post(`doctor/appointments/${appt_id}/state`, params)
  }
  getDetailAppointment (id) {
    return this.get(`doctor/appointments/${id}`)
  }
  getHistoriesByPerson (id) {
    return this.get(`doctor/persons/${id}/histories`)
  }
  getIndications (data) {
    return this.get(`doctor/disciplines`, data)
  }
  updateStateIndication (id, data) {
    return this.post(`doctor/disciplines/${id}/state`, data)
  }
  updateIndication (id, data) {
    return this.post(`doctor/disciplines/${id}`, data)
  }
  getClinic (data) {
    return this.get(`go/clinics`, data)
  }
  getClinics (data) {
    return this.get(`go/clinics`, data)
  }
  getNewNotifications () {
    return this.get(`doctor/notification/soft-unread`)
  }
  realAllNotifications () {
    return this.post(`doctor/notification/soft-read-all`)
  }
  getIndications2 (data) {
    return this.get(`doctor/disciplines-v2`, data)
  }
  getRoom (data) {
    return this.get(`doctor/room-calls`, data)
  }
  createRoom (data) {
    return this.post(`doctor/room-calls`, data)
  }
  getRoomInfo (id) {
    return this.get(`doctor/room-calls/${id}`)
  }
  updateRoomInfo (id, data) {
    return this.post(`doctor/room-calls/${id}`, data)
  }
  ratingRoomCall (id, params) {
    return this.post(`doctor/room-calls/${id}/rating`, params)
  }
  postWorkSpaces (params) {
    return this.post(`doctor/workspaces`, params)
  }
  getWorkSpacesAll (params) {
    return this.get(`doctor/workspaces`, params)
  }
  getWorkSpacesAllV2 (orgId, params) {
    return this.get(`go/core/org/workspace/${orgId}`, params)
  }
  // getWorkSpaces (id) {
  //   return this.get(`doctor/workspaces/${id}`)
  // }
  postBankAccounts (params) {
    return this.post(`doctor/bank-accounts`, params)
  }
  getBankAccounts () {
    return this.get(`doctor/bank-accounts`)
  }
  getBankAccountsById (params) {
    return this.get(`doctor/bank-accounts`, params)
  }
  getDoctorClinics (id) {
    return this.get(`doctor/clinics/${id}`)
  }
  postDoctorClinic (params) {
    return this.post(`doctor/clinics`, params)
  }

  updateStateHistory (id, data) {
    return this.post(`doctor/disciplines/${id}/state`, data)
  }
  getHomeStats (data) {
    return this.get(`doctor/stats`, data)
  }
  updatePersonNote (id, data) {
    return this.post(`doctor/doctor-notes/${id}`, data)
  }
  addPersonNote (data) {
    return this.post(`doctor/doctor-notes/`, data)
  }
  getWorkTime (data) {
    return this.get(`work-times`, data)
  }
  updateAppointment (id, data) {
    return this.post(`doctor/appointments/${id}`, data)
  }
  deleteAppointment (id) {
    return this.post(`doctor/appointments/${id}/delete`)
  }
  getAppointment (id) {
    return this.get(`doctor/appointments/${id}`)
  }
  postDoctorInvoices (id, params) {
    return this.post(`doctor/invoices/${id}`, params)
  }
  postInvoicesStatus (id, data) {
    return this.post(`doctor/invoices/${id}/status`, data)
  }
  postNotifyUnpaid (id) {
    return this.post(`doctor/invoices/${id}/notify-unpaid`)
  }
  getRequests (data) {
    return this.get(`doctor/requests`, data)
  }
  addRequests (data) {
    return this.post(`doctor/requests`, data)
  }
  getRequestDetail (id) {
    return this.get(`doctor/requests/${id}`)
  }
  processRequest (id) {
    return this.post(`doctor/requests/${id}/process`)
  }
  transferRequest (id, data) {
    return this.post(`doctor/requests/${id}/transfer`, data)
  }
  acceptRequest (id) {
    return this.post(`doctor/requests/${id}/accept`)
  }
  rejectRequest (id) {
    return this.post(`doctor/requests/${id}/reject`)
  }
  closeRequest (id, params) {
    return this.post(`doctor/requests/${id}/close`, params)
  }
  getCircleMember (data) {
    return this.get(`doctor/doctor-friends`, data)
  }
  getDoctorFriend (params) {
    return this.get(`doctor/doctor-friends`, params)
  }
  postDoctorFriend (params) {
    return this.post(`doctor/doctor-friends`, params)
  }
  postDoctorFriendsAccept (id, params) {
    return this.post(`doctor/doctor-friends/${id}/accept`)
  }
  postDoctorFriendsReject (id, params) {
    return this.post(`doctor/doctor-friends/${id}/reject`)
  }
  postDoctorFriendDelete (id) {
    return this.post(`doctor/doctor-friends/${id}/delete`)
  }
  postBankAccountsUpdate (id, params) {
    return this.post(`doctor/bank-accounts/${id}`, params)
  }
  postBankAccountsDelete (id) {
    return this.post(`doctor/bank-accounts/${id}/delete`)
  }
  postClinicEdit (id, params) {
    return this.post(`doctor/clinics/${id}`, params)
  }
  postOrgClinicEdit (id, params) {
    return this.post(`doctor/org/clinics/${id}`, params)
  }
  postDoctorEdit (id, params) {
    return this.post(`doctor/persons/${id}`, params)
  }
  getDoctorWorkTime (params) {
    return this.get(`doctor/work_times`, params)
  }
  postDoctorWorkTime (params) {
    return this.post(`doctor/work_times`, params)
  }
  postDoctorWorkTimeEdit (id, params) {
    return this.post(`doctor/work_times/${id}`, params)
  }
  postDoctorWorkTimeDelete (id, params) {
    return this.post(`doctor/work_times/${id}/delete`, params)
  }
  postDistanceTime (params) {
    return this.post(`doctor/distance_time`, params)
  }
  getDoctorExps (params) {
    return this.get(`doctor/exps`, params)
  }
  postDoctorExps (params) {
    return this.post(`doctor/exps`, params)
  }
  postDoctorExpsEdit (id, params) {
    return this.post(`doctor/exps/${id}`, params)
  }
  postDoctorExpsDelete (id) {
    return this.post(`doctor/exps/${id}/delete`)
  }
  getDoctorEdus (params) {
    return this.get(`doctor/edus`, params)
  }
  postDoctorEdus (params) {
    return this.post(`doctor/edus`, params)
  }
  postDoctorEdusEdit (id, params) {
    return this.post(`doctor/edus/${id}`, params)
  }
  postDoctorEdusDelete (id) {
    return this.post(`doctor/edus/${id}/delete`)
  }
  getDoctorTickets (params) {
    return this.get(`doctor/tickets`, params)
  }
  postDoctorTickets (params) {
    return this.post(`doctor/tickets`, params, null, false)
  }
  getDoctorTicketComments (params) {
    return this.get(`doctor/ticket-comments`, params)
  }
  postDoctorTicketComments (params) {
    return this.post(`doctor/ticket-comments`, params)
  }
  getDoctorTicketMedias (params) {
    return this.get(`doctor/ticket-medias`, params)
  }
  postDoctorTicketMedias (params) {
    return this.post(`doctor/ticket-medias`, params)
  }
  postDoctorTicketsStatus (id, params) {
    return this.post(`doctor/tickets/${id}`, params)
  }
  getDoctorTotalUnread () {
    return this.get('doctor/requests-unread')
  }
  getDoctorTotalRead (id) {
    return this.get(`doctor/requests/${id}/read`)
  }
  getRequestHistory (params) {
    return this.get(`doctor/request-histories`, params)
  }
  getAllPermissions (params) {
    return this.get(`doctor/workspace-permissions`, params)
  }
  getWorkspaceRoles (id, params) {
    return this.get(`doctor/workspaces/${id}/roles`, params)
  }
  getWorkspaceRolePermissions (workspace_id, params) {
    return this.get(`doctor/workspaces/${workspace_id}/role-permissions`, params)
  }
  getWorkspacePermissions (id, params) {
    return this.get(`doctor/workspaces/${id}/permissions`, params)
  }
  addNewRole (id, params) {
    return this.post(`doctor/workspaces/${id}/roles`, params)
  }
  addRolePermission (id, params) {
    return this.post(`doctor/workspaces/${id}/permissions-add`, params)
  }
  deleteRolePermission (id, params) {
    return this.post(`doctor/workspaces/${id}/permissions-delete`, params)
  }
  getDoctorWorkspace (id, params) {
    return this.get(`doctor/clinics/${id}/doctors`, params)
  }
  getDoctorWorkspaceV2 (id, params) {
    return this.get(`doctor/clinics/${id}/v2/doctors`, params)
  }
  changeDoctorRole (id, params) {
    return this.post(`doctor/workspaces/${id}/role-assign`, params)
  }
  postDoctorCalls (params) {
    return this.post(`doctor/calls`, params)
  }
  setDefaultWorkspace (id, params) {
    return this.post(`doctor/workspaces/${id}/default`, params)
  }
  getDoctorClinicPersons (params) {
    return this.get(`doctor/clinic-persons`, params)
  }
  getClinicDoctor (id, params) {
    return this.get(`doctor/clinics/${id}/doctors`, params)
  }
  postSettingRequest (id, params) {
    return this.post(`doctor/requests/${id}/setting`, params)
  }
  postMessageThread (id, params) {
    return this.post(`doctor/threads/${id}/messages`, params)
  }
  postAppointmentsState (id, params) {
    return this.post(`doctor/appointments/${id}/state`, params)
  }
  postRequestNumber (id, params) {
    return this.post(`doctor/requests/${id}/members`, params)
  }
  postAppointmentsUpdateStatus (id, params) {
    return this.post(`doctor/appointments/${id}/status`, params)
  }
  getAppointmentRequest (id, params) {
    return this.get(`doctor/requests/${id}/appointments`, params)
  }
  postRequestsMemberDetele (id, params) {
    return this.post(`doctor/requests/${id}/member-delete`, params)
  }
  postDoctorClinicDelete (id, params) {
    return this.post(`doctor/clinics/${id}/doctor-delete`, params)
  }
  getDoctorWorkspaceClinic (params) {
    return this.get(`doctor/workspace-requests`, params)
  }
  getDoctorTicketsByID (id) {
    return this.get(`doctor/tickets/${id}`)
  }
  acceptDoctorTicketsByID (id, params) {
    return this.post(`doctor/workspace-requests/${id}/accept`, params)
  }
  rejectDoctorTicketsByID (id, params) {
    return this.post(`doctor/workspace-requests/${id}/reject`, params)
  }
  getDiseases () {
    return this.get(`doctor/diseases`)
  }
  postPersonDiseases (params) {
    return this.post(`doctor/person-diseases`, params)
  }
  getSymtomps () {
    return this.get(`doctor/symtomps`)
  }
  postSymtomps (params) {
    return this.post(`doctor/person-symptoms`, params)
  }
  postQuestionGroupDisease (params) {
    return this.post(`doctor/question-group-disease`, params)
  }
  getPersonMedicine (id, params) {
    return this.get(`doctor/persons/${id}/person-medicines`, params)
  }
  getPersonSymptom (id, params) {
    return this.get(`doctor/persons/${id}/person-symptoms`, params)
  }
  postPersonMedicines (params) {
    return this.post(`doctor/person-medicines`, params)
  }
  getPersonsMedicines (person_id, params) {
    return this.get(`doctor/persons/${person_id}/person-medicines`, params)
  }
  getPersonsSymptoms (person_id) {
    return this.get(`doctor/persons/${person_id}/person-symptoms`)
  }
  getPersonsDiseases (person_id) {
    return this.get(`doctor/persons/${person_id}/person-diseases`)
  }
  getPersonsDiseasesDetail (id) {
    return this.get(`doctor/person-diseases/${id}`)
  }
  updatePersonsDiseases (id, params) {
    return this.put(`doctor/person-diseases/${id}`, params)
  }
  postPersonSymptoms (id, params) {
    return this.put(`doctor/person-symptoms/${id}`, params)
  }
  deletePersonDiseaseTags (params) {
    return this.post(`doctor/person-diseases/tags-delete`, params)
  }
  addPersonSymptomValue (params) {
    return this.post(`doctor/person-symptoms-value`, params)
  }
  uploadPersonDiseaseDocument (id, params) {
    return this.post(`doctor/persons/${id}/documents`, params)
  }
  getPersonDiseaseDocument (id, params) {
    return this.get(`doctor/persons/${id}/documents`, params)
  }
  getPersonDiseaseDocuments (params) {
    return this.get(`go/person-disease/documents`, params)
  }
  getPersonDiseases (params) {
    return this.get(`doctor/person-diseases`, params)
  }
  updateOrCreateCareTeam (params) {
    return this.post(`doctor/care-teams`, params)
  }
  personsDiseasesCreate (params) {
    return this.post(`doctor/person-diseases/create`, params)
  }
  personsDiseasesDetail (id, params) {
    return this.get(`doctor/person-diseases/${id}/detail`, params)
  }
  personsDiseasesUpdate (id, params) {
    return this.post(`doctor/person-diseases/${id}/update`, params)
  }
  personsDiseasesHistories (id, params) {
    return this.get(`doctor/person-diseases/${id}/histories`, params)
  }
  personsSurvivalStatSetting (params) {
    return this.post(`doctor/setting-monitor-survival`, params)
  }
  multiSurvivalStatSetting (person_diseases_id, params) {
    return this.post(`doctor/person-diseases/${person_diseases_id}/monitor-stats`, params)
  }
  getSurvivalStatSetting (id, params) {
    return this.get(`doctor/person-diseases/${id}/setting-monitor-survival`, params)
  }
  getPersonSymptoms (id, params) {
    return this.get(`doctor/person-diseases/${id}/person-symptoms`, params)
  }
  getPersonSymptomsValue (id, params) {
    return this.get(`doctor/person-diseases/${id}/person-symptoms-value`, params)
  }
  updatePersonStats (id, params) {
    return this.post(`doctor/persons/${id}/stats`, params)
  }
  getPersonByUserId (id, params) {
    return this.get(`doctor/user/${id}/person`, params)
  }
  postCareTeamsAccept (id, params) {
    return this.post(`doctor/care-teams/${id}/accept`, params)
  }
  postCareTeamsAcceptV2 (params) {
    return this.post('go/person-disease/doctor/accept', params)
  }
  getTestsByDiseases (id, params) {
    return this.get(`doctor/person-diseases/${id}/person-test`, params)
  }
  getPersonsCareplanSign (person_diseases_id) {
    return this.get(`doctor/person-diseases/${person_diseases_id}/careplan-sign`)
  }
  putPersonsCareplanSign (id, params) {
    return this.put(`doctor/careplan-sign/${id}`, params)
  }
  getPersonDiseaseTestResult (params) {
    return this.get(`doctor/person-test-result`, params)
  }
  createPersonDiseaseTestResult (params) {
    return this.post(`doctor/person-test-result`, params)
  }
  getResultsByDiseases (id, params) {
    return this.get(`doctor/person-diseases/${id}/person-exploration`, params)
  }
  getTestResultDetail (id, params) {
    return this.get(`doctor/person-test-result/${id}/show`, params)
  }
  multiCreatePersonTest (params) {
    return this.post(`doctor/person-test/multi`, params)
  }
  multiCreatePersonResult (params) {
    return this.post(`doctor/person-exploration/multi`, params)
  }
  postPersonMedicinesMonitor (id) {
    return this.post(`doctor/person-medicines/${id}/monitor`)
  }
  postCareTeamsReject (id) {
    return this.post(`doctor/care-teams/${id}/reject`)
  }
  getMedicineScheduleByDiseases (id, params) {
    return this.get(`doctor/person-diseases/${id}/medicine-schedules`, params)
  }
  createTopic (person_diseases_id, params) {
    return this.post(`doctor/person-diseases/${person_diseases_id}/topics/create`, params)
  }
  getTopics (person_diseases_id, params) {
    return this.get(`doctor/person-diseases/${person_diseases_id}/topics`, params)
  }
  getTopicDetail (id, params) {
    return this.get(`doctor/topics/${id}/show`, params)
  }
  postTopicComment (topic_id, params) {
    return this.post(`doctor/topic/${topic_id}/comments/create`, params)
  }
  getTopicComments (id, params) {
    return this.get(`doctor/topic/${id}/comments`, params)
  }
  readTopic (topic_id) {
    return this.post(`doctor/topic/${topic_id}/read`)
  }
  readPersonDiseases (id) {
    return this.post(`doctor/person-diseases/${id}/read`)
  }
  editTopic (topic_id, params) {
    return this.post(`doctor/topic/${topic_id}/update`, params)
  }
  editComment (comment_id, params) {
    return this.post(`doctor/comment/${comment_id}/update`, params)
  }
  deleteMedias (params) {
    return this.post(`doctor/medias/soft-delete/multi`, params)
  }
  getCallLogs (params) {
    return this.get(`doctor/call-logs`, params)
  }
  createConsultation (params) {
    return this.post(`doctor/consultations`, params)
  }
  updateStatusConsultation (id, status) {
    return this.post(`doctor/consultations/${id}/status/${status}`)
  }
  getConsultation (params) {
    return this.get(`doctor/consultations`, params)
  }
  getConsultationByIDV2 (id, params) {
    return this.get(`v2/doctor/consultations/${id}`, params)
  }
  updateConsultation (id, params) {
    return this.post(`doctor/consultations/${id}`, params)
  }
  getConsultationDetail (id) {
    return this.get(`doctor/consultations/${id}`)
  }
  getListFloors (params) {
    return this.get(`go/roombed/locations`, params)
  }
  getConsultationHistories (id, params) {
    return this.get(`doctor/consultations/${id}/histories`, params)
  }
  getDoctorsOfWorkspace (id, params) {
    return this.get(`doctor/clinic/${id}/doctors`, params)
  }
  getPersonsOfWorkspace (id, params) {
    return this.get(`doctor/clinic/${id}/persons`, params)
  }
  createCareTeam2 (params) {
    return this.post(`doctor/v2/care-teams`, params)
  }
  updateCareTeam2 (id, params) {
    return this.put(`doctor/v2/care-teams/${id}`, params)
  }
  getWorkTimeDoctor (id, params) {
    return this.get(`auth/work-time/${id}/show`, params)
  }
  multiCreateWorkTimeDoctor (params, h = null, s = true) {
    return this.post(`doctor/work-time/multi-create`, params, h, s)
  }
  createTwilioRoom (params) {
    return this.post(`doctor/twl-video/rooms`, params)
  }
  getAvaTwilioRooms (params) {
    return this.get(`doctor/twl-video/available-room`, params)
  }
  getTwilioToken (params) {
    return this.post(`doctor/twl-video/token`, params)
  }
  createStaff (params) {
    return this.post(`doctor/staff-create`, params)
  }
  getServiceCategory (params) {
    return this.get(`doctor/service-category`, params)
  }
  createServiceCategory (params) {
    return this.post(`doctor/service-category`, params)
  }
  showServiceCategory (id, params) {
    return this.get(`doctor/service-category/${id}/show`, params)
  }
  updateServiceCategory (id, params) {
    return this.post(`doctor/service-category/${id}/update`, params)
  }
  deleteServiceCategory (id, params) {
    return this.post(`doctor/service-category/${id}/delete`, params)
  }
  createAdvice (person_diseases_id, params) {
    return this.post(`doctor/person-diseases/${person_diseases_id}/advice`, params)
  }
  createAdviceTodos (advice_id, params) {
    return this.post(`doctor/person-diseases/advice/${advice_id}/multi-create-todos`, params)
  }
  updateAdviceTodos (advice_id, params) {
    return this.post(`doctor/person-diseases/advice/${advice_id}/multi-update-todos`, params)
  }
  getAdvices (person_diseases_id, params) {
    return this.get(`doctor/person-diseases/${person_diseases_id}/advice`, params)
  }
  updateAdvice (person_diseases_id, id, params) {
    return this.post(`doctor/person-diseases/${person_diseases_id}/advice/${id}`, params)
  }
  getSurvivalStats (params) {
    return this.get(`doctor/survival-stats`, params)
  }
  createSurvivalStats (params) {
    return this.post(`doctor/survival-stats`, params)
  }
  updateSurvivalStats (id, params) {
    return this.post(`doctor/survival-stats/${id}/update`, params)
  }
  updateSurvivalStatStatus (id) {
    return this.post(`doctor/survival-stats/${id}/status`)
  }
  showTemplate (id, params) {
    return this.get(`doctor/diseases-templates/${id}/show`, params)
  }
  createTemplates (params) {
    return this.get(`doctor/diseases-templates`, params)
  }
  getTemplates (clinic_id, params) {
    return this.get(`doctor/diseases-templates/${clinic_id}/get`, params)
  }
  updateTemplate (id, params) {
    return this.post(`doctor/diseases-templates/${id}/update`, params)
  }
  createCareTeam3 (params) {
    return this.post(`doctor/v3/care-teams/create`, params)
  }
  updateCareTeam3 (id, params) {
    return this.post(`doctor/v3/care-teams/${id}/update`, params)
  }
  getPersonStatHistories (person_id, params) {
    return this.get(`doctor/person-stats/${person_id}/histories`, params)
  }
  updatePersonStatsV2 (person_id, params) {
    return this.post(`doctor/person-stats/${person_id}/update`, params)
  }
  multiCreateDiseasesTemplateSymtomps (id, data) {
    return this.post(`doctor/diseases-templates/${id}/multi-add-symptoms`, data)
  }
  multiCreateDiseasesTemplateMonitorStats (id, data) {
    return this.post(`doctor/diseases-templates/${id}/multi-add-stats`, data)
  }
  deleteDiseasesTemplates (id) {
    return this.post(`doctor/diseases-templates/${id}/delete`)
  }
  saveAsTemplate (id, params) {
    return this.post(`doctor/person-diseases/${id}/save-template`, params)
  }
  createMdtRoom (params) {
    return this.post(`doctor/multidisciplinary-team/create`, params)
  }
  getMdtRooms (params) {
    return this.get(`doctor/multidisciplinary-team/get`, params)
  }
  showMdtRoom (id, showMessage) {
    return this.get(`doctor/multidisciplinary-team/${id}/show`, null, null, showMessage)
  }
  getMdtMembers (id, params) {
    return this.get(`doctor/multidisciplinary-team/${id}/member`, params)
  }
  addMdtMembers (id, params) {
    return this.post(`doctor/multidisciplinary-team/${id}/member`, params)
  }
  getAllDoctors (params) {
    return this.get(`doctor/list-doctors`, params)
  }
  getMdtDiseases (params) {
    return this.get(`doctor/mdt-room-diseases`, params)
  }
  showMdtDiseases (id, params) {
    return this.get(`doctor/mdt-room-diseases/${id}/show`, params)
  }
  updateMdtDiseases (id, params) {
    return this.post(`doctor/mdt-room-diseases/${id}`, params)
  }
  addMdtDiseases (params) {
    return this.post(`doctor/mdt-room-diseases`, params)
  }
  addMdtDiseasesV3 (params) {
    return this.post(`doctor/v3/mdt-room-diseases`, params)
  }
  uploadFileMdtDiseaes (params) {
    return this.post(`doctor/mdt-room-diseases/upload`, params)
  }
  mdtDiseaseStatus (id, params) {
    return this.post(`doctor/mdt-room-diseases/${id}/update-status`, params)
  }
  addMdtDiseasesFile (params) {
    return this.post(`doctor/mdt-room-disease-attachment`, params)
  }
  showMdtRoomByName (name, params) {
    return this.get(`doctor/multidisciplinary-team/${name}/show-name`, params, null, false)
  }
  joinMdtRoom (id, params) {
    return this.post(`doctor/multidisciplinary-team/${id}/join`, params)
  }
  getMdtCheckinMembers (params) {
    return this.get(`doctor/mdt-checkin`, params)
  }
  mdtCaseConcludingCreate (params) {
    return this.post(`doctor/public-mdt-case-concluding`, params)
  }
  mdtCaseConcludingUpdate (id, params) {
    return this.post(`doctor/public-mdt-case-concluding/${id}`, params)
  }
  mdtConcludingCreate (params) {
    return this.post(`doctor/mdt-room-disease-concluding`, params)
  }
  mdtConcludingUpdate (id, params) {
    return this.post(`doctor/mdt-room-disease-concluding/${id}`, params)
  }
  mdtConcludingUpload (id, params) {
    return this.post(`doctor/mdt-room-disease-concluding/${id}/upload`, params)
  }
  mdtCheckin (params) {
    return this.post(`doctor/mdt-checkin`, params)
  }
  mdtChangeRole (id, params) {
    return this.post(`doctor/multidisciplinary-team/${id}/role`, params)
  }
  getUrlPac (params) {
    return this.get(`doctor/pacs-dicoms/get-url`, params)
  }
  showMdtDiseaseDetail (mdt_room_id, person_diseases_id) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/show`)
  }
  getMdtDiseaseSymptoms (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/symptoms`, params)
  }
  getMdtDiseaseTestResults (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/test-result`, params)
  }
  getMdtDiseaseTests (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/test`, params)
  }
  getMdtDiseaseResults (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/results`, params)
  }
  getMdtDiseaseMedicines (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/medicines`, params)
  }
  getMdtDiseaseDocuments (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/documents`, params)
  }
  getMdtDiseaseMonitorStats (mdt_room_id, person_diseases_id, params) {
    return this.get(`doctor/mdt/${mdt_room_id}/disease/${person_diseases_id}/monitor-stats`, params)
  }
  getMdtDiseaseInProcess (mdt_room_id) {
    return this.get(`doctor/mdt-room-diseases/${mdt_room_id}/in-process`)
  }
  startMdtRoom (mdt_room_id) {
    return this.post(`doctor/mdt-room-log/${mdt_room_id}/start`)
  }
  endMdtRoom (mdt_room_id) {
    return this.post(`doctor/mdt-room-log/${mdt_room_id}/end`)
  }

  createPacs (params) {
    return this.post(`doctor/pacs-dicoms`, params)
  }
  updatePacs (id, params) {
    return this.post(`doctor/pacs-dicoms/${id}`, params)
  }
  inviteToClinic (id, params) {
    return this.post(`core/v1/doctor/clinics/${id}/invite-doctors`, params)
  }
  joinWSOTP (id, params, header, show = true) {
    return this.post(`core/v1/doctor/clinics/${id}/add-doctor-by-otp`, params, header, show)
  }
  removeMdtMember (member_id) {
    return this.post(`doctor/mdt-room-member/${member_id}/remove`)
  }
  createMeetRoom (params) {
    return this.post(`doctor/meet-room`, params)
  }
  getMeetRooms (params) {
    return this.get(`doctor/meet-room`, params)
  }
  getMeetRoomsV2 (params) {
    return this.get(`go/meet-rooms/clinic`, params)
  }
  getMeetRoomMembers (meet_room_id, params) {
    return this.get(`doctor/meet-room/${meet_room_id}/members`, params)
  }
  removeMeetMember (member_id) {
    return this.post(`doctor/meet-room-member/${member_id}/remove-member`)
  }
  showMeetRoom (meet_room_id) {
    return this.get(`doctor/meet-room/${meet_room_id}/show`)
  }
  getConectiveDoctors (params) {
    return this.get('doctor/conective-doctors', params)
  }
  getOrgConnectiveDoctors (params) {
    return this.get('doctor/organizations/connective-doctors', params)
  }
  changeMeetMemberRole (meet_room_id, params) {
    return this.post(`doctor/meet-room/${meet_room_id}/change-role`, params)
  }
  getMeetRoomAttachs (meet_room_id, params) {
    return this.get(`doctor/meet-room/${meet_room_id}/attachment`, params)
  }
  multiAddMeetRoomAttachs (params) {
    return this.post(`doctor/meet-room-attachment`, params)
  }
  showMeetRoomAttach (id) {
    return this.get(`doctor/meet-room/${id}/show`)
  }
  deleteMeetRoomAttach (id) {
    return this.post(`doctor/meet-room-attachment/${id}/delete`)
  }
  getTodayMeetRoomConcluding (meet_room_id) {
    return this.get(`doctor/meet-room/${meet_room_id}/today-concluding`)
  }
  createMeetRoomConcluding (meet_room_id, params) {
    return this.post(`doctor/meet-room/${meet_room_id}/concluding`, params)
  }
  updateMeetRoomConcluding (id, params) {
    return this.post(`doctor/meet-room-concluding/${id}/update`, params)
  }
  createMdtDiseaseOperation (params) {
    return this.post(`doctor/mdt-room-disease-operation`, params)
  }
  updateMdtDiseaseOperation (id, params) {
    return this.post(`doctor/mdt-room-disease-operation/${id}`, params)
  }
  deleteMdtDiseaseAttach (id) {
    return this.post(`doctor/mdt-room-disease-attachment/${id}/delete`)
  }
  getMeetRoomConcludings (params) {
    return this.get(`doctor/meet-room-concluding/get`, params)
  }
  getMeetRoomConcludingsV2 (params) {
    return this.get(`go/meet-rooms/concluding`, params)
  }
  getMyCircles (params) {
    return this.get(`doctor/circles/my`, params)
  }
  getMyCirclesV2 (params) {
    return this.get(`go/circle/doctor/list`, params)
  }
  getMemberByUserId (circleId, userId, header = null, show = true) {
    return this.post(`doctor/circles/${circleId}/member/${userId}`, null, header, show)
  }
  acceptJoinCircle (circleId) {
    return this.post(`go/circle/doctor/acceptJoin/${circleId}`)
  }
  acceptCircleMember (memId) {
    return this.post(`go/circle/doctor/accept/${memId}`)
  }
  rejectCircleMember (memId) {
    return this.post(`doctor/circle-member/${memId}/reject`)
  }
  banCircleMember (memId) {
    return this.post(`doctor/circle-member/${memId}/ban`)
  }
  unbanCircleMember (memId) {
    return this.post(`doctor/circle-member/${memId}/unban`)
  }
  createCircle (p) {
    return this.post(`go/circle/doctor/create`, p)
  }
  createLiveCircle (p) {
    return this.post(`go/circle/doctor/create`, p)
  }
  updateCircle (id, p) {
    return this.post(`doctor/circles/${id}/update`, p)
  }
  getCircles (params) {
    return this.get(`doctor/circles`, params)
  }
  joinCircle (id) {
    return this.post(`doctor/circles/${id}/join`)
  }
  deleteCircle (id) {
    return this.post(`doctor/circles/${id}/delete`)
  }
  changeCircleToPublic (id) {
    return this.post(`go/circle/doctor/changePublic/${id}`)
  }
  showCircle (id) {
    return this.get(`doctor/circles/${id}/show`)
  }
  getCircleMembers (id, p) {
    return this.get(`doctor/circles/${id}/members`, p)
  }
  showUserByEmail (p) {
    return this.post(`doctor/show-user-by-email`, p)
  }
  addCircleMembers (id, p) {
    return this.post(`doctor/circles/${id}/members`, p)
  }
  addCircleAttachments (p) {
    return this.post(`doctor/circle-attachment`, p)
  }
  addCircleFolder (circle_id, params) {
    return this.post(`doctor/circle-attachment/${circle_id}/folder/create`, params)
  }
  getCircleAttachments (id, p, hd, sm) {
    return this.get(`doctor/circles/${id}/attachment`, p, hd, sm)
  }
  getCircleAttachmentsV2 (id, params) {
    return this.get(`go/core/circle/${id}/attachment`, params)
  }
  deleteCircleAttachment (id) {
    return this.post(`doctor/circle-attachment/${id}/delete`)
  }
  getTwlCvsToken (p) {
    return this.post(`core/v1/twilio-conversation/token`, p)
  }
  getMdtSession (id, params) {
    return this.get(`doctor/mdt-room/${id}/sessions`, params)
  }
  updateMdtMemberJoinDate (member_id, params) {
    return this.post(`doctor/mdt-room-member/${member_id}/join_date`, params)
  }
  updateMdtRoom (id, params) {
    return this.post(`doctor/multidisciplinary-team/${id}/update`, params)
  }
  showMdtSession (id, params) {
    return this.get(`doctor/mdt-session/${id}/show`, params)
  }
  getMdtDiseasesBySession (session_id, params) {
    return this.get(`doctor/mdt-session/${session_id}/cases`, params)
  }

  getPublicMtdRooms (params) {
    return this.get(`doctor/public-mdt-rooms`, params)
  }
  createPublicMtdRoom (data) {
    return this.post(`doctor/public-mdt-rooms`, data)
  }
  updatePublicMtdRoom (id, data) {
    return this.put(`doctor/public-mdt-rooms/${id}`, data)
  }
  getPublicMtdRoomDetails (id) {
    return this.get(`doctor/public-mdt-rooms/${id}`)
  }
  getMembersOfPublicMdt (id) {
    return this.get(`doctor/public-mdt-rooms/${id}/members`)
  }
  changeRoleOfPublicMdt (id, data) {
    return this.put(`doctor/public-mdt-rooms/${id}/member-role`, data)
  }
  getSessionsOfPublicMdt (id) {
    return this.get(`doctor/public-mdt-rooms/${id}/sessions`)
  }
  addMemberOfPublicMdt (id, data) {
    return this.post(`doctor/public-mdt-rooms/${id}/members`, data)
  }
  removeMemberOfPublicMdt (id, data) {
    return this.put(`doctor/public-mdt-rooms/${id}/members`, data)
  }
  getPublicSessionDetail (id) {
    return this.get(`doctor/public-sessions/${id}`)
  }
  getPublicCaseDetail (id) {
    return this.get(`doctor/public-cases/${id}`)
  }
  createPublicMdtCase (data) {
    return this.post(`doctor/public-mdt-cases`, data)
  }
  sendCasesToOrg (data) {
    return this.post(`doctor/public-mdt-cases/from-mdt-diseases`, data)
  }
  updatePublicMdtCase (id, data) {
    return this.post(`doctor/public-mdt-cases/${id}`, data)
  }
  getPublicMdtCases (p) {
    return this.get(`doctor/public-mdt-cases`, p)
  }
  getPublicMtdCases (params) {
    return this.get(`doctor/public-cases`, params)
  }
  createPublicMdtCaseOperation (params) {
    return this.post(`doctor/public-mdt-case-operation`, params)
  }
  updatePublicMdtCaseOperation (id, params) {
    return this.post(`doctor/public-mdt-case-operation/${id}`, params)
  }
  getHomePageSummary () {
    return this.get('doctor/homepage/summary')
  }
  getOrgMembers (p) {
    return this.get(`doctor/organization-members`, p)
  }
  getOrgMembersV2 (p) {
    return this.get(`go/core/org/members`, p)
  }
  postOrgMembers (params) {
    return this.post('doctor/organization-members', params)
  }
  postOrgMembersV2 (params) {
    return this.post('doctor/organization-members/V2', params)
  }
  deleteOrgMembers (id) {
    return this.delete(`doctor/organization-members/${id}`)
  }
  getOrgs (p) {
    return this.get(`doctor/my-organizations`, p)
  }
  createDoctorKYC (params) {
    return this.post(`doctor/doctor-kyc`, params)
  }
  getDoctorKYC (params) {
    return this.get(`doctor/doctor-kyc`, params)
  }
  updateDoctorKYC (id, params) {
    return this.post(`doctor/doctor-kyc/${id}`, params)
  }
  inviteDoctorWs (params) {
    return this.post(`doctor/workspaces/invite-member`, params)
  }
  getInvitedDoctorWs (id, params) {
    return this.get(`doctor/workspaces/${id}/invitations`, params)
  }
  deleteInvitedDoctorWs (id, header, showMessage) {
    return this.delete(`doctor/workspace-invitations/${id}`, header, showMessage)
  }
  postTelemedicine (params) {
    return this.post('doctor/telemedicine-logs', params)
  }
  putTelemedicine (id, params) {
    return this.put(`doctor/telemedicine-logs/${id}`, params)
  }
  createCircleAttachment (params) {
    return this.post(`doctor/circle-attachment/create/single`, params)
  }
  linkPacsToHSSK (params) {
    return this.post(`go/person-disease/doctor/attach_dicom`, params)
  }
  createIndicationAttachment (id, params) {
    return this.post(`v1/indications/${id}/file`, params)
  }
  showCircleFolder (circle_id, folder_id, hd, sm) {
    return this.get(`doctor/circle-attachment/${circle_id}/folder/${folder_id}/show`, null, hd, sm)
  }
  updateCircleFolder (circle_id, folder_id, params, hd, sm) {
    return this.post(`doctor/circle-attachment/${circle_id}/folder/${folder_id}/update`, params, hd, sm)
  }
  getBADT (params) {
    return this.get(`v1/medical-records`, params)
  }
  fetchBADTByApptId (params) {
    return this.get(`go/medical_record/workspace`, params)
  }
  getBADTWaiting (params) {
    return this.get(`v1/medical-records/waiting`, params)
  }
  postBADT (params, hd, sm) {
    return this.post(`v1/medical-records`, params, hd, sm)
  }
  linkReExamToBADT (params) {
    return this.post(`go/re_exam/medical_records`, params)
  }
  putBADT (id, params) {
    return this.put(`v1/medical-records/${id}`, params)
  }
  deleteBADT (id, params) {
    return this.delete(`v1/medical-records/${id}`, params)
  }
  getPatientsWS (id, params) {
    return this.get(`workspace/${id}/patients/get`, params)
  }
  postPatientsWS (params) {
    return this.post(`workspace/patients/create`, params)
  }
  postCheckPatient (params, hd, sm) {
    return this.post(`workspace/patients/check`, params, hd, sm)
  }
  getPatientWithID (clinic_id, person_id, params) {
    return this.get(`workspace/${clinic_id}/patients/${person_id}/show`, params)
  }
  getDetailBADT (id, params) {
    return this.get(`v1/medical-records/${id}/master`, params)
  }
  getDetailMedicalRecordMaster (id) {
    return this.get(`go/medical_record/master/${id}`)
  }
  getWorkspaceMRByPatientId (clinic_id, person_id, params) {
    return this.get(`v1/medical-records/workspace/${clinic_id}/patient/${person_id}`, params)
  }
  sendMdtOperationToMr (medical_record_id, operation_id) {
    return this.post(`medical-records/${medical_record_id}/operation/${operation_id}/link`)
  }
  sendMdtConcludingToMr (medical_record_id, operation_id) {
    return this.post(`medical-records/${medical_record_id}/concluding/${operation_id}/link`)
  }
  getMdtConcludingByMr (medical_record_id, params) {
    return this.get(`medical-records/${medical_record_id}/concluding`, params)
  }
  postBADTMasters (params) {
    return this.post(`v1/medical-record-masters`, params)
  }
  getMdtOperationByMr (medical_record_id, params) {
    return this.get(`medical-records/${medical_record_id}/operation`, params)
  }
  getMedicalRecordTest (medical_record_id, params) {
    return this.get(`medical-records/${medical_record_id}/test`, params)
  }
  getMedicalRecordWorkspace (clinic_id, person_id) {
    return this.get(`v1/medical-records/workspace/${clinic_id}/person/${person_id}`)
  }
  getWorkspacePatients (clinic_id, p) {
    return this.get(`workspace/${clinic_id}/patients/get`, p)
  }
  getWsFeature (params) {
    return this.get(`v1/workspace-settings`, params)
  }
  getWsFeatureV2 (id) {
    return this.get(`go/core/org/ws-services/${id} `)
  }
  getOrgFeature (id) {
    return this.get(`go/core/org/org-services/${id} `)
  }
  changeMedicalRecordState (medical_record_id, status_id, params) {
    return this.post(`v1/medical-records/${medical_record_id}/state/${status_id}/update`, params)
  }
  putMedicalRecord (id, params) {
    return this.put(`v1/medical-records/${id}`, params)
  }
  getMRStates (medical_record_id) {
    return this.get(`v1/medical-records/${medical_record_id}/states`)
  }
  createMRChecklistStatus (params) {
    return this.post(`doctor/mr-checklists-status/create`, params)
  }
  getERMTreatments (id, params) {
    return this.get(`v1/medical-records/${id}/emr-treatments`, params)
  }
  getERMTreatmentsByDate (id, params) {
    return this.get(`v1/medical-records/${id}/emr-treatments-by-date`, params)
  }
  getERMTreatmentByID (id, params) {
    return this.get(`v1/emr-treatments/${id}`, params)
  }
  getERMTreatmentByIDV2 (id, params) {
    return this.get(`go/medical_record/treatment/${id}`, params)
  }
  signTreatmentByID (data) {
    return this.put(`go/medical_record/treatment`, data)
  }
  postERMTreatment (params) {
    return this.post(`v1/emr-treatments`, params)
  }
  updateERMTreatment (id, params) {
    return this.post(`v1/emr-treatments/${id}`, params)
  }
  getERMCares (id, params) {
    return this.get(`v1/medical-records/${id}/emr-cares`, params)
  }
  getERMCaresByDate (id, params) {
    return this.get(`v1/medical-records/${id}/emr-cares-by-date`, params)
  }
  getERMCareByID (id, params) {
    return this.get(`v1/emr-cares/${id}`, params)
  }
  postERMCare (params) {
    return this.post(`v1/emr-cares`, params)
  }
  updateERMCare (id, params) {
    return this.post(`v1/emr-cares/${id}`, params)
  }
  getERMTreatSums (id, params) {
    return this.get(`v1/medical-records/${id}/emr-treat-sums`, params)
  }
  getERMTreatSumByID (id, params) {
    return this.get(`v1/emr-treat-sums/${id}`, params)
  }
  postERMTreatSum (params) {
    return this.post(`v1/emr-treat-sums`, params)
  }
  updateERMTreatSum (id, params) {
    return this.post(`v1/emr-treat-sums/${id}`, params)
  }
  getSuggestionChecklistItem (params) {
    return this.get(`doctor/mr-checklists-status/get`, params)
  }
  getPrescriptions (params) {
    return this.get(`doctor/prescriptions`, params)
  }
  getPrescriptionByID (id) {
    return this.get(`doctor/prescriptions/${id}`)
  }
  createPrescription (params) {
    return this.post(`doctor/prescriptions`, params)
  }
  updatePrescription (id, params) {
    return this.put(`doctor/prescriptions/${id}`, params)
  }
  getDocuments (params) {
    return this.get('doctor/documents', params)
  }
  myAppts (params) {
    return this.get('doctor/appts/me', params)
  }
  allAppts (params) {
    return this.get('doctor/appts', params)
  }
  postAppts (params) {
    return this.post('go/core/doctor/appointment', params)
  }
  getConsultingRooms (params) {
    return this.get('doctor/consulting-rooms', params)
  }
  getReExaminationDetail (params) {
    return this.get('go/patient_visit/relationship', params)
  }
  getConsultingRoomsV3 (params) {
    return this.get('go/consulting_rooms', params)
  }
  getConsultingRoomsV2 (params) {
    return this.post('go/doctor_work_time_settings/get_cr_by_dr_clinic', params)
  }
  getAllConsultingRoomsByDoctorID (id, params) {
    return this.get(`go/core/cr/listAllCrByDoctor/${id}`, params)
  }
  getAllConsultingRooms (params) {
    return this.get(`go/core/cr/listAllHodo`, params)
  }
  getConsultingRoomByID (id, params) {
    return this.get(`doctor/consulting-rooms/${id}`, params)
  }
  getConsultingRoomByIDV2 (id, params) {
    return this.get(`go/ConsultingRoom/${id}`, params)
  }
  createConsultingRoom (params) {
    return this.post('doctor/consulting-rooms', params)
  }
  createConsultingRoomV2 (params) {
    return this.post('go/consulting_rooms', params)
  }
  updateConsultingRoom (id, params) {
    return this.put(`doctor/consulting-rooms/${id}`, params)
  }
  updateConsultingRoomV2 (id, params) {
    return this.put(`go/consulting_rooms/${id}`, params)
  }
  deleteConsultingRoom (id, params) {
    return this.delete(`doctor/consulting-rooms/${id}`, params)
  }
  getConsultingRoomDoctors (params) {
    return this.get('doctor/consulting-room-doctors', params)
  }
  createConsultingRoomDoctor (params) {
    return this.post('doctor/consulting-room-doctors', params)
  }
  createConsultingRoomDoctorV2 (params) {
    return this.post('go/DoctorWorkTimeSetting', params)
  }
  toggleStatusConsultingRoomDoctor (id, params) {
    return this.put(`doctor/consulting-room-doctors/${id}/toggle-status`, params)
  }
  toggleStatusConsultingRoom (id, params) {
    return this.put(`doctor/consulting-rooms/${id}/toggle-status`, params)
  }
  getCRWorkTimes (params) {
    return this.get('auth/v1/cr-worktimes', params)
  }
  getCRWorkTimesV2 (id, params) {
    return this.get(`go/DoctorWorkTimeSetting/${id}`, params)
  }
  createCRWorkTimes (params) {
    return this.post('doctor/cr-worktimes', params)
  }
  createCRWorkTimesV2 (params) {
    return this.post('go/AddSlotDoctorWorkTimeSetting', params)
  }
  getHealthRecords (params) {
    return this.get('v2/doctor/health-records', params)
  }
  getHealthRecordByID (id, params) {
    return this.get(`v2/doctor/health-records/${id}`, params)
  }
  createHealthRecords (params) {
    return this.post('v2/doctor/health-records', params)
  }
  updateHealthRecords (id, params) {
    return this.put(`v2/doctor/health-records/${id}`, params)
  }
  getAppts (id) {
    return this.get(`doctor/appts/${id}`)
  }
  getApptsV2 (id) {
    return this.get(`v2/doctor/appointments/${id}`)
  }
  updateAppts (id, p) {
    return this.put(`doctor/appts/${id}`, p)
  }
  getPersonsV2 (params) {
    return this.get(`v2/doctor/persons`, params)
  }
  postPersonsV2 (params) {
    return this.post(`v2/doctor/persons`, params)
  }
  getPersonWithID (person_id, params) {
    return this.get(`v2/doctor/persons/${person_id}`, params)
  }
  getIndicationsV2 (params) {
    return this.get('v2/doctor/indications', params)
  }
  createIndication (params) {
    return this.post(`v2/doctor/indications`, params)
  }
  getEMRStatistics (params) {
    return this.get('v1/medical-records/statistics', params)
  }
  getEMRStates (mr_id) {
    return this.get(`doctor/emr/${mr_id}/states`)
  }
  updateEmrIndication (id, params) {
    return this.put(`v2/doctor/indications/${id}`, params)
  }
  updateEmrResult (id, params) {
    return this.post(`doctor/person-test-result/${id}/update`, params)
  }
  changeEMRStates (mr_id, params) {
    return this.post(`doctor/emr/${mr_id}/change-state`, params)
  }
  postTestResult (id, params) {
    return this.post(`doctor/person-test-result/${id}/update`, params)
  }
  getIndicationsV2Id (id) {
    return this.get(`v2/doctor/indications/${id}`)
  }
  getIndicationClasses (params) {
    return this.get('doctor/indication-classes', params)
  }
  getIndicationCategories (params) {
    return this.get('doctor/indication-categories', params)
  }
  postIndicationOder (params) {
    return this.post('doctor/indication-orders', params)
  }
  getIndicationOder (id, params) {
    return this.get(`doctor/indication-orders/${id}`, params)
  }
  putIndicationOder (id, params) {
    return this.put(`doctor/indication-orders/${id}`, params)
  }
  createIndicationAttach (id, params) {
    return this.post(`doctor/indications/${id}/file`, params)
  }
  deleteIndicationAttach (id, params) {
    return this.delete(`doctor/indication-files/${id}`, params)
  }
  getIndicationById (id, params) {
    return this.get(`doctor/indications/${id}`, params)
  }
  getExaminationResult (id, params) {
    return this.get(`doctor/examination-results/${id}`, params)
  }
  createExaminationResult (params) {
    return this.post(`doctor/examination-results/conclusion`, params)
  }
  updateExaminationResult (id, params) {
    return this.put(`doctor/examination-results/${id}/conclusion`, params)
  }
  createIndicationConclusions (params) {
    return this.post('doctor/indication-conclusions', params)
  }
  getIndicationOrders (params) {
    return this.get('doctor/indication-orders', params)
  }
  getIndicationComments (id) {
    return this.get(`doctor/indication/${id}/comment`, null, null, false)
  }
  updateIndicationComments (id, params) {
    return this.put(`doctor/indication/comment/${id}/update`, params, null, false)
  }
  deleteIndicationComments (id, params) {
    return this.delete(`doctor/indication/comment/${id}/delete`, params, null, false)
  }
  createIndicationComments (id, params) {
    return this.post(`doctor/indication/${id}/comment`, params, null, false)
  }
  getIndicationsByMR (params) {
    return this.get(`doctor/indications`, params)
  }
  updateStatusIndication (id, params) {
    return this.put(`doctor/indications/${id}/status`, params)
  }
  getIndicationPrint (id, params) {
    return this.get(`doctor/indication-orders/${id}/print`, params)
  }
  getIndicationPrintV2 (id, params) {
    return this.get(`go/indication_orders/${id}`, params)
  }
  getIndicationsCategories (params) {
    return this.get(`doctor/indication-categories`, params)
  }
  sendIndicationToPatient (params) {
    return this.post(`doctor/indication-informs`, params, null, false)
  }
  changeConsultationStatus (id, status) {
    return this.post(`doctor/consultations/${id}/status/${status}`, null, null, false)
  }
  sendIndicationToPatientHR (params) {
    return this.post(`v2/doctor/indication-informs`, params, null, false)
  }
  sendConsultationToPatient (id, params) {
    return this.post(`doctor/consultations/${id}/send-results`, params, null, false)
  }
  createConsultationAttach (params) {
    return this.post(`doctor/consultation-files`, params)
  }
  allApptsV2 (paramsData) {
    const configs = {
      params: {
        ...paramsData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return this.get('v2/doctor/appointments', null, null, configs)
  }

  allApptsV3 (paramsData) {
    const configs = {
      params: {
        ...paramsData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return this.get('v3/doctor/appointments', null, null, configs)
  }
  myApptsV2 (id, params) {
    return this.get(`v2/doctor/appointments/${id}`, params)
  }
  deleteConsultationFile (id) {
    return this.delete(`doctor/consultation-files/${id}`)
  }
  deleteMedicines (id) {
    return this.delete(`doctor/person-medicines/${id}`)
  }
  deleteIndication (id) {
    return this.delete(`doctor/indications/${id}`)
  }
  ApptStatus (id, params) {
    return this.post(`v2/doctor/appointments/${id}/status`, params)
  }
  ApptTreatment (id, params) {
    return this.put(`v2/doctor/appointments/${id}/treatment-type`, params)
  }
  getOrgDoctor (id, params) {
    return this.get(`doctor/organizations/${id}/doctors`, params)
  }
  createEmrIndication (mr_id, params) {
    return this.post(`doctor/emr/${mr_id}/indication`, params)
  }
  getEmrIndication (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/indications`, params)
  }
  showEmrIndication (id) {
    return this.get(`doctor/emr/indication/${id}/show`, null, null, false)
  }
  createEmrIndicationResult (mr_id, params) {
    return this.put(`doctor/emr/indication/${mr_id}/result`, params)
  }
  getEmrIndicationResult (result_id) {
    return this.get(`doctor/emr/indication/result/${result_id}/show`)
  }
  postEmrIndicationResultFile (result_id, params) {
    return this.post(`doctor/emr/indication/result/${result_id}/files`, params)
  }
  getVitalsignHeaders (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/vitalsign-headers`, params)
  }
  showVitalsignHeader (id, params) {
    return this.get(`doctor/emr/vitalsign-header/${id}/show`, params)
  }
  createVitalsignHeaders (params) {
    return this.post(`doctor/emr/vitalsign-header`, params)
  }
  updateVitalsignHeaders (vitalsign_id, params) {
    return this.post(`doctor/emr/vitalsign-header/${vitalsign_id}/update`, params)
  }
  subscribeEmrIndication (id, params) {
    return this.post(`doctor/emr/indication/${id}/subscribe`, params)
  }
  readEmrIndication (id, params) {
    return this.put(`doctor/emr/indication/${id}/read`, params)
  }
  postEmrIndication (id, params) {
    return this.post(`doctor/emr/indication/${id}/update`, params)
  }
  createSurgery (mr_id, params) {
    return this.post(`doctor/emr/${mr_id}/surgery`, params)
  }
  getSurgeries (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/surgeries`, params)
  }
  showSurgery (surgery_id, params) {
    return this.get(`doctor/emr/surgery/${surgery_id}/show`, params)
  }
  updateSurgery (surgery_id, params) {
    return this.post(`doctor/emr/surgery/${surgery_id}/update`, params)
  }
  getAnesthesias (mr_id, params) {
    return this.get(`v1/medical-records/${mr_id}/emr-anesthesias`, params)
  }
  getShowAnesthesia (anesthesia_id, params) {
    return this.get(`v1/emr-anesthesias/${anesthesia_id}`, params)
  }
  postAnesthesia (params) {
    return this.post('v1/emr-anesthesias', params)
  }
  putAnesthesia (anesthesia_id, params) {
    return this.post(`v1/emr-anesthesias/${anesthesia_id}`, params)
  }
  getNutritionAdmissions (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/nutrition-admissions`, params)
  }
  getNutritionAdmissionsById (nutrition_admission_id, params) {
    return this.get(`doctor/emr/nutrition-admission/${nutrition_admission_id}/show`, params)
  }
  postNutritionAdmissions (mr_id, params) {
    return this.post(`doctor/emr/${mr_id}/nutrition-admission`, params)
  }
  updateNutritionAdmissions (nutrition_admission_id, params) {
    return this.post(`doctor/emr/nutrition-admission/${nutrition_admission_id}/update`, params)
  }
  getAllNutrition (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/nutritions`, params)
  }
  getNutritionHospital (mr_id, params) {
    return this.get(`doctor/emr/${mr_id}/nutrition-hospitals`, params)
  }
  postNutritionHospital (mr_id, params) {
    return this.post(`doctor/emr/${mr_id}/nutrition-hospital`, params)
  }
  postNutritionHospitalById (id, params) {
    return this.post(`doctor/emr/nutrition-hospital/${id}/update`, params)
  }
  getNutritionHospitalById (nutrition_hospital_id, params) {
    return this.get(`doctor/emr/nutrition-hospital/${nutrition_hospital_id}/show`, params)
  }
  createVitalsign (params) {
    return this.post(`doctor/emr/vitalsign/create`, params)
  }
  updateVitalsign (vitalsign_id, params) {
    return this.put(`doctor/emr/vitalsign/${vitalsign_id}/update`, params)
  }
  signDocument (params) {
    return this.post(`doctor/sign`, params)
  }
  setEmrDocument (params) {
    if (!params) return
    return this.post(`doctor/emr-documents/printing-file`, params)
  }
  getEmrDocument (params) {
    if (!params) return
    return this.post(`doctor/emr-documents/printing-url`, params)
  }
  setAliasPerson (params) {
    if (!params) return
    return this.post(`doctor/mdt-room-alias`, params)
  }
  updateAliasPerson (id, params) {
    if (!id) return
    return this.post(`doctor/mdt-room-alias/${id}`, params)
  }
  getPatientVisits (paramsData) {
    const configs = {
      params: {
        ...paramsData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return this.get('doctor/patient-visits', null, null, configs)
  }
  getPatientVisitsV2 (paramsData) {
    const configs = {
      params: {
        ...paramsData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return this.get('go/patient_visits', null, null, configs)
  }
  setPatientVisits (data) {
    if (!data) return
    return this.post(`doctor/patient-visits`, data)
  }
  getPatientVisitsById (id, params) {
    if (!id) return
    return this.get(`doctor/patient-visits/${id}`, params)
  }
  updatePatientVisitsById (id, data) {
    if (!id) return
    return this.post(`doctor/patient-visits/${id}`, data)
  }
  updatePatientVisitsV2 (id, data) {
    return this.put(`go/patient_visits/${id}`, data)
  }
  getPatientsReception (paramsData) {
    const configs = {
      params: {
        ...paramsData
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
    return this.get('doctor/v2/persons', null, null, configs)
  }
  deleteFile (id) {
    return this.delete(`doctor/patient-visits/files/${id}`)
  }
  // updateApptByIdV2 (id, data) {
  //   if (!id || !data) return
  //   return this.post(`v2/doctor/appointments/${id}`, data)
  // }
  callPatient (id, data) {
    if (!id) return
    return this.post(`doctor/called-up/${id}`, data)
  }
  addConsulation (data) {
    if (!data) return
    return this.post('doctor/patient-visit/consultations', data)
  }
  updateConsulation (data, id) {
    if (!data) return
    return this.put(`doctor/patient-visit/consultations/${id}`, data)
  }
  sendResultPatientVisit (params) {
    return this.post('v3/doctor/indication-informs', params)
  }
  checkExitPerson (params) {
    return this.get('doctor/checkexist?limit=5', params)
  }
  checkExistPatient (params) {
    return this.get('go/doctor/persons/exist', params)
  }
  printDocument (params) {
    return this.post('doctor/emr-documents/printing-html', params)
  }
  getPharmas (params) {
    return this.get('go/pharmainventoryproducts/data', params)
  }
  getPharmasUniversal (params) {
    return this.get('go/pharmainventoryproducts/list', params)
  }
  getRemainSlot (params) {
    return this.post('go/getdoctoravailbydate', params)
  }
  getConsultingRoomWithDoctor (params, wsId) {
    return this.get(`go/DoctorWorkTimeSettingByWs/${wsId}`, params)
  }
  getIndicationTemplateByCateId (id) {
    return this.get(`doctor/indication-template/${id}`)
  }
  getListPatientVisitInHealthRecord (params) {
    return this.get('doctor/patient-visits', params)
  }
  postPersonsV3 (params) {
    return this.post(`v3/doctor/persons`, params)
  }
  updatePersonInfoV3 (id, params) {
    return this.put(`v3/doctor/persons/${id}`, params)
  }
  getPersonInfoV2 (id) {
    return this.get(`doctor/persons/v2/${id}`)
  }
  getResuscitations (params) {
    return this.get('doctor/emr/resuscitations', params)
  }
  postResuscitations (params) {
    return this.post('doctor/emr/resuscitation', params)
  }
  getShowResuscitation (anesthesia_id, params) {
    return this.get(`doctor/emr/resuscitations/${anesthesia_id}/show`, params)
  }
  putResuscitation (anesthesia_id, params) {
    return this.post(`doctor/emr/resuscitations/${anesthesia_id}/update`, params)
  }
  //
  getDecalaration (params) {
    return this.get('go/core/emr-declarations', params)
  }
  postDecalaration (params) {
    return this.post('go/core/emr-declarations', params)
  }
  putDecalaration (id, params) {
    return this.put(`go/core/emr-declarations/${id}`, params)
  }
  //
  deleteEmrExamWoundAttachment (id, params) {
    if (!id || !params) return
    return this.post(`v1/emr-examwound-attachment/${id}/delete`, params)
  }
  //
  getReExaminations (params) {
    return this.get('go/core/doctor/appointment/reexams', params)
  }
  postReExamination (params) {
    return this.post('go/core/doctor/appointment/reexam', params)
  }
  getReExamination (id) {
    return this.get(`go/core/doctor/appointment/reexam/${id}`)
  }
  updateReExamination (id, params) {
    return this.put(`go/core/doctor/appointment/reexam/${id}`, params)
  }
  //
  uploadFileWithTenant (params) {
    return this.post(`doctor/upload`, params)
  }
  postCopyMdtRoomDisease (params) {
    return this.post('doctor/multidisciplinary-team/10626/copy-mdt-room-diseases', params)
  }
  getOrgRelated (params) {
    return this.get(`go/core/org/related`)
  }
  postSubOrganization (params) {
    return this.post(`doctor/organizations/subs`, params, {
      'Content-Type': 'multipart/form-data'
    })
  }
  getSubOrganization (id, params) {
    return this.get(`go/core/org/subs/${id}`, params)
  }
  getSubOrganizationDetail (id) {
    return this.get(`go/core/org/${id}`)
  }
  postUpdateSubOrganization (id, params) {
    return this.post(`doctor/organizations/${id}`, params, {
      'Content-Type': 'multipart/form-data'
    })
  }
  getMdtDiseaseInRoom (mdt_room_id, params) {
    return this.get(`go/core/mdt/list/${mdt_room_id}`, params)
  }
  getMdtDiseaseByDate (mdt_room_id, params) {
    return this.get(`go/core/mdt/surgerylist/${mdt_room_id}`, params)
  }
  getMediaRecordByPersonId (id, params) {
    return this.get(`go/core/medicalRecord/person/${id}`, params)
  }
  checkAvailableDoctorByMonthCr (params) {
    return this.post(`go/checkdoctoravailbymonthv2`, params)
  }
  createNewOrganization (params) {
    return this.post(`go/core/org/createOrgWithToken`, params)
  }
  signMdtDiseaseDetail (params) {
    return this.post('doctor/sign', params)
  }
  postCreateTreatmentConfirmation (params) {
    return this.post('go/core/emr/treatmentconfirmation', params)
  }
  getTreatmentConfirmation (params) {
    return this.get(`go/core/emr/treatmentconfirmation`, params)
  }
  getDetailTreatmentConfirmationDetail (id) {
    return this.get(`go/core/emr/treatmentconfirmation/${id}`)
  }
  postUpdateTreatmentConfirmation (id, params) {
    return this.post(`go/core/emr/treatmentconfirmation/${id}`, params)
  }
  createMedicalOrder (params) {
    return this.post(`go/core/emr/medicalorder`, params)
  }
  getListMedicalOrder (params) {
    return this.get(`go/core/emr/medicalorder`, params)
  }
  getMedicalOrderById (id) {
    return this.get(`go/core/emr/medicalorder/${id}`)
  }
  deleteTemplateMedicalOrder (id) {
    return this.delete(`go/core/emr/medicalorder/${id}`)
  }
  postUpdateMedicalOrder (id, params) {
    return this.post(`go/core/emr/medicalorder/${id}`, params)
  }
  getAdministrativeForms (params) {
    return this.get('go/core/emr/administrativeform', params)
  }
  postCreateAdministrativeForms (params) {
    return this.post('go/core/emr/administrativeform', params)
  }
  getAdministrativeFormsContent (type) {
    return this.get(`go/core/emr/administrativeform/types/${type}`)
  }
  getAdministrativeFormDetail (id) {
    return this.get(`go/core/emr/administrativeform/${id}`)
  }
  postUpdateAdministrativeForm (id, params) {
    return this.post(`go/core/emr/administrativeform/${id}`, params)
  }
  getLastVitalsign (id, params) {
    return this.get(`go/core/emr/medicalorder/vitalsign/${id}`, params)
  }
  postCreateGuardian (params) {
    return this.post('go/core/emr/administrativeform/guardian', params)
  }
  getGuardian (id) {
    return this.get(`go/core/emr/administrativeform/guardian/${id}`)
  }
  postUpdateGuardian (id, params) {
    return this.post(`go/core/emr/administrativeform/guardian/${id}`, params)
  }
  getListEmrTemplate (params) {
    return this.get('go/core/emr/emrtemplate', params)
  }
  getListService (params) {
    return this.get('go/service_catalogs', params)
  }
  postCreateEmrTemplate (params) {
    return this.post('go/core/emr/emrtemplate', params)
  }
  postUpdateEmrTemplate (id, params) {
    return this.post(`go/core/emr/emrtemplate/${id}`, params)
  }
  getEmrTemplateDetail (id) {
    return this.get(`go/core/emr/emrtemplate/${id}`)
  }
  getEmrDocumentTemplateJson (params) {
    return this.get('go/core/emr/emrtemplate/json', params)
  }
  getDocsTemplateList (params) {
    return this.get('go/core/emr/emrtemplate/document', params)
  }
  postCreateDocTemplate (params) {
    return this.post('go/core/emr/emrtemplate/document', params)
  }
  getDocTemplateDetail (id) {
    return this.get(`go/core/emr/emrtemplate/document/${id}`)
  }
  postUpdateDocTemplate (id, params) {
    return this.post(`go/core/emr/emrtemplate/document/${id}`, params)
  }
  // create room
  createRoomService (params, id) {
    return this.post('go/rooms/create', params)
  }
  createFloor (params) {
    return this.post('go/roombed/locations', params)
  }
  createBedService (params) {
    return this.post('go/beds/create', params)
  }
  updateBed (params, id) {
    return this.put(`go/beds/update/${id}`, params)
  }
  getListRoomService (params) {
    return this.get('go/rooms', params)
  }
  getListRoomServiceWithBeds (params) {
    return this.get('go/beds/listRooms', params)
  }
  updateRoomService (id, params) {
    return this.put(`go/rooms/update/${id}`, params)
  }
  getDetailRooms (id, wsid, params) {
    return this.get(`go/rooms/${id}?workspaceID=${wsid}`, params)
  }
  // reservations
  createReservation (params) {
    return this.post('go/reservations/create', params)
  }
  getReservations (params) {
    return this.get('go/reservations', params)
  }
  updateReservations (params, id) {
    return this.put(`go/reservations/update/${id}`, params)
  }
  postUrlImage (url) {
    return this.post(`go/core/medicalRecord/file?url=${url}`)
  }
  deleteBed (id) {
    return this.delete(`go/beds/${id}`)
  }
  deleteRoom (id) {
    return this.delete(`go/rooms/${id}`)
  }
  importPatientFromExcelFile (params) {
    return this.post(`go/workspace/patient/import`, params)
  }
  getPatientList (params) {
    return this.get(`go/workspace/patient`, params)
  }
  postPatient (params) {
    return this.post(`go/workspace/patient/create`, params)
  }
  updatePatient (params, id) {
    return this.post(`go/workspace/patient/update/${id}`, params)
  }
  /**
   * Live stream circle
   */
  getTokenLiveCircle (data) {
    return this.post(`go/livestream/token/generate`, data)
  }
  getStartLiveStream (data) {
    return this.post(`go/circle/doctor/livestream/start`, data)
  }
  getStopLiveStream (data) {
    return this.post(`go/circle/doctor/livestream/stop`, data)
  }

  getListSupplier (params, id) {
    return this.get(`go/core/org/workspace/providers/${id}`, params)
  }
  //

  postIndicationProductOder (params) {
    return this.post('go/doctor/order/create', params)
  }
  getLiveCircleMembers (params) {
    return this.get(`go/circle/doctor/listmember`, params)
  }
  getTabsTotalNumber (id) {
    return this.get(`go/circle/doctor/statistic/${id}`)
  }
  getListPatientNotInCircle (params) {
    return this.get(`go/core/doctor/listPersonNotInCircle`, params)
  }
  getListPatientIdsNotInCircle (params) {
    return this.get(`go/core/doctor/listPersonNotInCircleUserIds`, params)
  }
  invitePatientToJoinCircle (params) {
    return this.post(`go/circle/doctor/invitepatient`, params)
  }
  acceptAndNotifyCircleMember (memId) {
    return this.post(`go/circle/doctor/accept/${memId}`)
  }
  rejectAndNotifyCircleMember (memId) {
    return this.delete(`go/circle/doctor/reject/${memId}`)
  }
  acceptConvertPersonToPatient (id) {
    return this.post(`go/core/connectorg/request/accept/${id}`)
  }
  rejectConvertPersonToPatient (id, params) {
    return this.post(`go/core/connectorg/request/reject/${id}`, params)
  }
  getLisData (id) {
    return this.get(`go/core/lis/indications/${id}`)
  }
  getPersonList (params) {
    return this.get(`go/doctor/persons`, params)
  }
  getPatientConnectRequest (id) {
    return this.get(`go/doctor/persons/${id}`)
  }
  checkIfOrgHasServices (id) {
    return this.get(`go/service_role/count_org_services/${id}`)
  }
  getOrgServiceList (id) {
    return this.get(`go/service_role/roles/${id}`)
  }
  getOrgServiceRoleDetail (org_mem_id) {
    return this.get(`go/service_role/roles/org_mem/${org_mem_id}`)
  }
  assignRolesOrg (params) {
    return this.post(`go/service_role/roles/assign`, params)
  }
  getMedicines (id, params) {
    return this.get(`go/pharma/pharma_invetory_product/doctor/${id}`, params)
  }
  getInventoryMedicines (params) {
    return this.get('go/pharma/pharma_invetory/list', params)
  }
  postHandleAvailable (params) {
    return this.post('go/pharma/pharma_invetory_product/handle_avaible', params)
  }
  postHandleUpdateAvailable (params) {
    return this.post('go/pharma/pharma_invetory_product/update_handle_avaible', params)
  }
  acceptAndNotifyAll (cid) {
    return this.post('go/circle/doctor/acceptAll/' + cid)
  }
  getListRolesInRoom (type) {
    return this.get(`go/livestream/template/roles?type=${type}`)
  }
  assignConferenceRole (params) {
    return this.put('go/circle/doctor/assign', params)
  }
  checkMyRoleConferenceRoom (params) {
    return this.post('go/circle/doctor/role/conference', params)
  }
  createConferenceRoom (id) {
    return this.post('go/circle/doctor/create/roomConference/' + id)
  }
  createLiveRoom (id) {
    return this.post('go/circle/doctor/create/livestream/' + id)
  }
  createOrgLivestreamRoom (id) {
    return this.post('go/organizations/livestream/' + id)
  }
  getOrgLivestreamRoom (id) {
    return this.get('go/organizations/' + id)
  }
  assignOrgLivestreamRole (params) {
    return this.post('go/organizations/livestream/assign_role', params)
  }
  uploadFileTenant (params) {
    return this.post(`go/core/files/requestUpload`, params)
  }
  getDoctorWorkTimeSetting (params) {
    return this.get(`go/doctor_work_time_settings`, params)
  }
  getDoctorByEmail (p) {
    return this.post(`go/doctor/email`, p)
  }
  // Search up to date function
  getSearchResult (params) {
    return this.get(`go/uptodate/auto_complete`, params)
  }
  getListArticle (params) {
    return this.get(`go/uptodate/search`, params)
  }
  getDetailArticle (params) {
    return this.get(`go/uptodate/detail`, params)
  }
  // Search up to date function
  signPrescription (parmas) {
    return this.post(`go/pharma/prescription/sign`, parmas)
  }
  getSupplierInfo (id) {
    return this.get(`go/partner/products/suppliers/${id}`)
  }
  postUpdateSupplierInfo (id, params) {
    return this.put(`go/partner/products/suppliers/${id}`, params)
  }
  createProcessor (params) {
    return this.post('go/partner/processors', params)
  }
  sendNotificationLiveStream (params) {
    return this.post('go/circle/notifications/multi', params)
  }
  approveConferenceGuest (id) {
    return this.post(`go/conference/conference_registrants/approve/${id}`)
  }
  getShowConference () {
    return this.get(`go/core/org/ws-services/conference`)
  }
  getUptodateGraphic (params) {
    return this.get(`go/uptodate/graphic_detailed`, params, '', {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
  getUptodateAbstract (params) {
    return this.get(`go/uptodate/citation`, params)
  }

  getUptodateMultipleImages (params) {
    return this.get(`go/uptodate/graphic`, params, '', {
      paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
    })
  }
  getUptodateGrade (params) {
    return this.get(`go/uptodate/grade`, params)
  }
  getWSService () {
    return this.get(`go/core/org/ws-services`)
  }
  getAddressVietnam (params) {
    return this.get('go/address/vn', params)
  }
  getHodoBenefits (params) {
    return this.get('go/hodo/services/public/benefit', params)
  }
  getPackageInfo (params) {
    return this.get('go/hodo/services/current-package', params)
  }
  postRedeemPackage (params) {
    return this.post('go/hodo/services/redeem', params)
  }
  getVoucherInfo (params) {
    return this.get('go/hodo/services/voucher', params)
  }
  getPatientSyncInfo (params) {
    return this.get('go/doctor/persons/sync_infor', params)
  }
  createPersonV2 (data) {
    return this.post('go/auth/persons', data)
  }
  postCreateEMR (params) {
    return this.post('go/medical_record', params)
  }
  getEMRByPID (params) {
    return this.get(`go/medical_record/person`, params)
  }
  updateIsPublicVideoConference (params) {
    return this.put(`go/conference/conference_circle/is_show_url_record`, params)
  }
  getMDTRoomDetail (id) {
    return this.get(`go/mdt_rooms/${id}`)
  }
  getFinishCaseMdtRoom (id) {
    return this.get(`go/mdt_rooms/statistic_mdt_disease/${id}`)
  }
  putSyncPatient (ref_id) {
    return this.put(`go/doctor/persons/sync_infor/${ref_id}`)
  }
  putSyncEMR (ref_id) {
    return this.put(`go/medical_record/sync_infor/${ref_id}`)
  }
  getEMRVersion (params) {
    return this.get('go/medical_record_master_changes', params)
  }
  getEMRVersionDetail (id) {
    return this.get(`go/medical_record_master_changes/${id}`)
  }
  putAssignCircleAdmin (params) {
    return this.put('go/circle/doctor/assign/admin', params)
  }
  putUpdateEMR (id, params) {
    return this.put(`go/medical_record/medical_record_master/${id}`, params)
  }
  getStreamGenerationToken () {
    return this.get(`go/getStream/generateToken`)
  }
  postUploadVideosCircle (params) {
    return this.post(`go/circle_medias`, params)
  }
  getVideosCircle (params) {
    return this.get(`go/circle_medias`, params)
  }
  postUploadVideos (params) {
    return this.post('go/core/files/video-uploads', params)
  }
  registerEncryption () {
    return this.post(`go/getStream/registerEncryption`)
  }
  createNewChannel (data) {
    return this.post(`go/getStream/createChannel`, data)
  }
  deleteChannel (data) {
    return this.post(`go/getStream/deleteChannel`, data)
  }
  getChannel (id) {
    return this.get(`go/getStream/channel/${id}`)
  }
  getRelationshipByPID (params) {
    return this.get(`go/relationship`, params)
  }
  handleGetCircleDetailV2 (id) {
    return this.get(`go/circle/doctor/${id}`)
  }
  addMemberToChannel (params) {
    return this.post(`go/getStream/addMemberChannel`, params)
  }
  removeMemberOfCircle (params) {
    return this.post(`go/circle/doctor/removeMember`, params)
  }
  updatePatientV2 (id, params) {
    return this.put(`go/auth/persons/${id}`, params)
  }
  requestJoinChatCircle (params) {
    return this.post(`go/circle/chat/request_join`, params)
  }
  acceptRequestJoinChatCircle (params) {
    return this.post(`go/circle/chat/add_join`, params)
  }
  getMarketplaceProducts (params) {
    return this.get(`go/ecom/products/supplier`, params)
  }
  getMKManufacturers (params) {
    return this.get(`go/ecom/manufacturer`, params)
  }
  getMKBrands (params) {
    return this.get(`go/ecom/brand`, params)
  }
  getMKDosages (params) {
    return this.get(`go/ecom/dosage_form`, params)
  }
  getMKOrigins (params) {
    return this.get('go/ecom/origin', params)
  }
  getMKPackings (params) {
    return this.get('go/ecom/specification', params)
  }
  getMKUnits (params) {
    return this.get('go/ecom/unit', params)
  }
  submitAccumulatePoints (params) {
    return this.post(`go/ecom/hodo_point/config`, params)
  }
  getMKSupplierInfo (org_id) {
    return this.get(`go/ecom/supplier/${org_id}`)
  }
  getMarketplaceByID (params) {
    return this.get(`go/partner/products/suppliers`, params)
  }
  getExchangeRateByOrgId (id, params) {
    return this.get(`go/ecom/exchange-rates/org/${id}`, params)
  }
  postDepositPointsForDoctors (params) {
    return this.post(`go/ecom/hodo_point/addpoints`, params)
  }
  getDepositHistory (params) {
    return this.get(`go/ecom/buyer_transactions`, params)
  }
  getDoctorsV2 (params) {
    return this.get(`go/doctor/list`, params)
  }
  getDepositDetail (id, params) {
    return this.get(`go/ecom/buyer_transactions/${id}`, params)
  }
  getDepositMembers (id, params) {
    return this.get(`go/ecom/buyer_transactions/admin/${id}`, params)
  }
  leaveCircle (params) {
    return this.post(`go/circle/doctor/leave_circle`, params)
  }
  getIndicationService (params) {
    return this.get(`go/service_catalogs`, params)
  }
  getLocalService (params) {
    return this.get(`go/service_local`, params)
  }
  getIndicationServiceByID (id) {
    return this.get(`go/service_transaction_slip/${id}`)
  }
  getDetailServiceCatalog (id) {
    return this.get(`go/service_catalogs/${id}`)
  }
  getDetailServiceById (id) {
    return this.get(`go/service_local/${id}`)
  }
  getDetailServiceCategoryById (id) {
    return this.get(`go/service_category/${id}`)
  }
  createIndicationV2 (params) {
    return this.post(`go/indication_orders`, params)
  }
  createServiceCatalog (params) {
    return this.post(`go/service_catalogs`, params)
  }
  createServiceLocal (params) {
    return this.post(`go/service_local`, params)
  }
  createIndicationReceipt (params) {
    return this.post(`go/service-order/create`, params)
  }
  getListIndicationReceipt (params) {
    return this.get(`go/service_transaction_slip`, params)
  }
  getIndicationDetailV2 (id) {
    return this.get(`go/indication_orders/${id}`)
  }
  getListServiceCategory (params) {
    return this.get(`go/service_category`, params)
  }
  postCreateServiceCategory (params) {
    return this.post(`go/service_category`, params)
  }
  putUpdateServiceCategory (id, params) {
    return this.put(`go/service_category/${id}`, params)
  }
  getListServiceLocals (params) {
    return this.get(`go/service_local`, params)
  }
  getListServiceProvider (params) {
    return this.get(`go/service_providers`, params)
  }
  configServiceForDoctorCR (params) {
    return this.post(`go/doctor_work_time_settings/integrate_service`, params)
  }
  updateServiceCatalog (id, params) {
    return this.put(`go/service_catalogs/${id}`, params)
  }
  updateServiceLocal (id, params) {
    return this.put(`go/service_local/${id}`, params)
  }
  getServiceTransactionSlip (params) {
    return this.get(`go/service_transaction_slip`, params)
  }
  getServiceTransactionSlipWS (params) {
    return this.get(`go/service_transaction_slip/workspace`, params)
  }
  getDetailServiceTransactionSlip (id) {
    return this.get(`go/service_transaction_slip/${id}`)
  }
  getListServiceTransaction (params) {
    return this.get(`go/service_transactions`, params)
  }
  postCreateTransactionSlip (params) {
    return this.post(`go/service_transaction_slip`, params)
  }
  postCreateServiceOrder (params) {
    return this.post(`go/service-order/create`, params)
  }
  postRequestPayment (params) {
    return this.post(`go/core/payment/requestpayment`, params)
  }
  updateTransactionSlip (id, params) {
    return this.put(`go/service_transaction_slip/${id}`, params)
  }
  paymentTransactionSlipOff (tx_code) {
    return this.post(`go/service_transaction_slip/requestPaymentOfl/${tx_code}`)
  }
  sendOTPVerifyIndication (params) {
    return this.post(`go/user/validate_by_phone`, params)
  }
  checkOTPVerifyIndication (params) {
    return this.post(`go/user/confirm_otp`, params)
  }
  sendReceiptToPatient (params) {
    return this.post(`go/doctor/persons/validate_acc_slip`, params)
  }
  update10mmsMeetRoomById (id, data) {
    return this.put(`go/meet-rooms/update_room_100/${id}`, data)
  }
  getCredentialMeetRoom (data) {
    return this.post(`go/meet-rooms/credential_member`, data)
  }
  getLisIndicationsResultData (parmas) {
    return this.get(`go/core/lis/indications/results`, parmas)
  }
  getIndicationByWsId (params) {
    return this.get(`go/indications`, params)
  }
  sendIndicationResult (params) {
    return this.post(`go/indications/realease_result`, params)
  }
  sendResultByPatientVisit (params) {
    return this.post(`go/indications/realease_result/patient_visits`, params)
  }
  sendPatientVisitResult (params) {
    return this.post(`go/patient_visit/realease_result`, params)
  }
  requestPaymentMultipleSlips (params) {
    return this.post(`go/service_transaction_slip/workspace`, params)
  }
  sendNotification (params) {
    return this.post(`go/patient_visits/called_up`, params)
  }
  getEmrTemplateConfigs (params) {
    return this.get(`go/emr_template_configs`, params)
  }
  getDetailPatientVisit (id) {
    return this.get(`go/patient_visits/${id}`)
  }
  getOverviewPayment (params) {
    return this.get(`go/service_transaction_slip/report-overview`, params)
  }
  createPrescriptionV2 (params) {
    return this.post(`go/patient-visit/prescription`, params)
  }
  updatePrescriptionV2 (id, params) {
    return this.put(`go/patient-visit/prescription/${id}`, params)
  }
  getPrescriptionByIDV2 (id) {
    return this.get(`go/pharma/prescription/${id}`)
  }
  getDoctorHIS (params) {
    return this.get(`go/hodo-hv/get-hodo-rela`, params)
  }
  syncServiceLocal (params) {
    return this.post(`go/service_local/sync_from_cataglog`, params)
  }
  getRefPartnerPatients (params) {
    return this.get(`go/ref_partner_patients`, params)
  }
  createPrescriptionForm (params) {
    return this.post(`go/prescription_form`, params)
  }
  getPrescriptionForms (params) {
    return this.get(`go/prescription_form`, params)
  }
  updatePrescriptionForm (id, params) {
    return this.put(`go/prescription_form/${id}`, params)
  }
  updateConsultationV2 (id, params) {
    return this.post(`go/patient_visits/consultation/${id}`, params)
  }
  addPatientToHSSK (params) {
    return this.post(`go/doctor/persons/person_disease`, params)
  }
  updateIndicationV2 (id, params) {
    return this.put(`go/indications/${id}`, params)
  }
  getSignatureTimestamp (params) {
    return this.post('go/signing-process/get-timestamp', params)
  }
  getSignaturesDetail (params) {
    return this.post('go/signing-process/signatures', params)
  }
  getOtpTvMode (params) {
    return this.post('go/tv_mode/otp', params)
  }
  getListLeaveCertificates (params) {
    return this.get(`go/leave_certificates`, params)
  }
  getDetailLeaveCertificates (id) {
    return this.get(`go/leave_certificates/${id}`)
  }
  createLeaveCertificates (params) {
    return this.post(`go/leave_certificates`, params)
  }
  updateLeaveCertificates (id, params) {
    return this.put(`go/leave_certificates/${id}`, params)
  }
  getListTransitTickets (params) {
    return this.get(`go/transit_tickets`, params)
  }
  getDetailTransitTickets (id) {
    return this.get(`go/transit_tickets/${id}`)
  }
  createTransitTickets (params) {
    return this.post(`go/transit_tickets`, params)
  }
  updateTransitTickets (id, params) {
    return this.put(`go/transit_tickets/${id}`, params)
  }
  getConsultationTemplates (params) {
    return this.get(`go/doctor/consultations/get_template`, params)
  }
  createMdtOperation (params) {
    return this.post(`go/mdt_operation`, params)
  }
  updateMdtOperation (id, params) {
    return this.put(`go/mdt_operation/${id}`, params)
  }
  getMdtOperationByID (id) {
    return this.get(`go/mdt_operation/${id}`)
  }
  deleteVitalSignHeader (id) {
    return this.delete(`go/emr_vitalsign_headers/${id}`)
  }
  deleteVitalSigns (params) {
    return this.post(`go/emr_vitalsigns/delete-multiple`, params)
  }
  getListBookedBed (params) {
    return this.get(`go/reservations/mobiles`, params)
  }
  getListBedSlots (params) {
    return this.get(`go/beds/slots`, params)
  }
  getListPatientInWs (params) {
    return this.get(`go/doctor/persons/ws`, params)
  }
  getDetailReservation (id) {
    return this.get(`go/reservations/${id}`)
  }
  createBedSlot (params) {
    return this.post(`go/reservations/create`, params)
  }
  checkout (id, params) {
    return this.put(`go/reservations/change-state/${id}`, params)
  }
  changeBed (params) {
    return this.post(`go/reservations/transfer`, params)
  }
  cancelBed (id, params) {
    return this.put(`go/reservations/change-state/${id}`, params)
  }
  createNote (params) {
    return this.post(`go/emr_notes/create`, params)
  }
  getListNotes (params) {
    return this.get(`go/emr_notes`, params)
  }
  activeAccount (params) {
    return this.post(`go/user/create`, params)
  }
  getAvailableBalance (params) {
    return this.get(`go/user/balance`, params)
  }
}
