import HIDBaseRequest from '../HIDBaseRequest'

export default class EHCSyncRequest extends HIDBaseRequest {
  addEmrParticipant (params) {
    return this.post('go/addemrparticipant', params)
  }
  generateTextToVoice (data) {
    return this.post(`go/voice-request`, data)
  }
  getHeartRate (data) {
    return this.post(`go/fetch-hr`, data)
  }
  deleteEMRCare (data) {
    return this.post(`go/delete-emr-care`, data)
  }
  getListEmrReimbursementGroupByDate (params) {
    return this.get(`go/get-emr-reimbursement-group-by-date`, params)
  }
  createEmrReimbursementRequest (params) {
    return this.post(`go/create-emr-reimbursement-request`, params)
  }
  createEmrReimbursementConfirm (params) {
    return this.post(`go/create-emr-reimbursement-confirm`, params)
  }
  updateEmrReimbursementRequest (params) {
    return this.post(`go/update-emr-reimbursement-request`, params)
  }
  updateEmrReimbursementConfirm (params) {
    return this.post(`go/update-emr-reimbursement-confirm`, params)
  }
  getDetailEmrReimbursementConfirm (id) {
    return this.get(`go/get-emr-reimbursement-confirm/${id}`)
  }
  getDetailEmrReimbursementRequest (id) {
    return this.get(`go/get-emr-reimbursement-request/${id}`)
  }
  deleteEmrReimbursementConfirm (id) {
    return this.delete(`go/delete-emr-reimbursement-confirm/${id}`)
  }
  deleteEmrReimbursementRequest (id) {
    return this.delete(`go/delete-emr-reimbursement-request/${id}`)
  }
  getListCDXN (params) {
    return this.post(`go/emr-indications`, params)
  }
  getDetailCDXN (params) {
    return this.post(`go/get-indication-services`, params)
  }
}
