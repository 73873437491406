import BaseRequest from '../BaseRequest'

export default class ClinicRequest extends BaseRequest {
  getDoctors (params) {
    return this.get('clinic/doctors', params)
  }

  getDoctorDetail (id) {
    return this.get('clinic/doctors/' + id)
  }

  postDoctor (data) {
    return this.post('clinic/doctors', data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  postDoctorUpdate (id, data) {
    return this.post('clinic/doctors/' + id, data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  deleteDoctor (id) {
    return this.post(`clinic/doctors/${id}/delete`)
  }

  getClinicMe () {
    return this.get(`clinic/me`)
  }

  updateClinicMe (data) {
    return this.post(`clinic/me`, data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  getClinicPersons (params) {
    return this.get(`clinic/clinic-persons`, params)
  }

  getClinicPersonDetail (id) {
    return this.get(`clinic/clinic-persons/${id}`)
  }

  getPersons (params) {
    return this.get('clinic/persons', params)
  }

  getPersonDetail (id) {
    return this.get('clinic/persons/' + id)
  }

  postPerson (data) {
    return this.post('clinic/persons', data)
  }

  updatePersonInfo (id, params) {
    return this.post(`clinic/persons/${id}`, params)
  }

  updatePersonStats (person_id, params) {
    return this.post(`clinic/persons/${person_id}/stats`, params)
  }

  deletePerson (id) {
    return this.post('clinic/persons/' + id + '/delete')
  }

  getAppointments (params) {
    return this.get(`clinic/appointments`, params)
  }

  addAppointment (data) {
    return this.post(`clinic/appointments`, data)
  }

  getClinicStats () {
    return this.get(`clinic/stats`)
  }

  getNotifications () {
    return this.get(`clinic/notifications`)
  }

  getNotificationDetail (id) {
    return this.get(`clinic/notifications/${id}`)
  }

  getDetailAppointment (id) {
    return this.get(`clinic/appointments/${id}`)
  }

  updateState (appt_id, params) {
    return this.post(`clinic/appointments/${appt_id}/state`, params)
  }

  getTemplates (params) {
    return this.get(`clinic/templates`, params)
  }

  getTemplateDetail (params) {
    return this.get(`clinic/template-detail`, params)
  }

  updateTemplateData (id, data) {
    return this.post(`clinic/templates/${id}`, data)
  }

  uploadPatientList (data) {
    return this.post(`clinic/persons-import`, data)
  }

  updateHistory (id, data) {
    return this.post(`clinic/disciplines/${id}`, data)
  }

  updateStateHistory (id, data) {
    return this.post(`clinic/disciplines/${id}/state`, data)
  }

  getHistoriesByPerson (id, data) {
    return this.get(`clinic/persons/${id}/histories`, data)
  }

  getTransactions (data) {
    return this.get(`clinic/transactions`, data)
  }

  postTransactions (id, data) {
    return this.post(`clinic/transactions/${id}/status`, data)
  }

  getTransactionsTotal (data) {
    return this.get(`clinic/transactions-total`, data)
  }

  getTransactionsTotalPerson (data) {
    return this.get(`clinic/transactions-total/person`, data)
  }

  payForTransaction (data) {
    return this.post(`clinic/transactions`, data)
  }

  updateTransaction (id, data = {}) {
    return this.post(`clinic/transactions/${id}`, data)
  }

  getWorkTime () {
    return this.get(`clinic/work_times`)
  }

  postWorkTime (data) {
    return this.post(`clinic/work_times`, data)
  }

  updateWorkTime (id, data) {
    return this.post(`clinic/work_times/${id}`, data)
  }

  removeWorkTime (id) {
    return this.post(`clinic/work_times/${id}/delete`)
  }

  getIndicationCommon (data) {
    return this.get(`clinic/dti-commons`, data)
  }

  postIndicationCommon (data) {
    return this.post(`clinic/dti-commons`, data)
  }

  uploadIndicationCommon (data) {
    return this.post(`clinic/dti-commons-import`, data)
  }

  updateIndicationCommon (id, data) {
    return this.post(`clinic/dti-commons/${id}`, data)
  }

  deleteIndicationCommon (id) {
    return this.post(`clinic/dti-commons/${id}/delete`)
  }

  getIndicationGroup (data) {
    return this.get(`clinic/discipline-templates`, data)
  }

  getIndicationGroupDetail (id) {
    return this.get(`clinic/discipline-templates/${id}`)
  }

  postGroupIndication (data) {
    return this.post(`clinic/discipline-templates`, data)
  }

  deleteGroupIndication (id) {
    return this.post(`clinic/discipline-templates/${id}/delete`)
  }

  updateGroupIndication (id, data) {
    return this.post(`clinic/discipline-templates/${id}`, data)
  }

  getIndicationGroupItems (data) {
    return this.get(`clinic/discipline-templates-items`, data)
  }

  addIndicationGroupItem (id, data) {
    return this.post(`clinic/discipline-templates-items`, data)
  }

  updateIndicationGroupItem (id, data) {
    return this.post(`clinic/discipline-templates-items/${id}`, data)
  }

  deleteIndicationGroupItem (id) {
    return this.post(`clinic/discipline-templates-items/${id}/delete`)
  }

  getDetaiIndication (id) {
    return this.get(`clinic/disciplines/${id}`)
  }

  getIndications (data) {
    return this.get(`clinic/disciplines`, data)
  }

  getCheckinLists (data) {
    return this.get(`clinic/checkins`, data)
  }

  getReportsForDoctor (params) {
    return this.get(`clinic/report/doctors`, params)
  }

  getReportsForPatientRevenue (params) {
    return this.get(`clinic/report/patient-revenues`, params)
  }

  getNewNotifications () {
    return this.get(`clinic/notification/unread`)
  }

  realAllNotifications () {
    return this.get(`clinic/notification/read-all`)
  }
  getRoomCall (params) {
    return this.get(`clinic/room-calls`, params)
  }
  getInvites (params) {
    return this.get(`clinic/invites`, params)
  }
  postInvites (id, params) {
    return this.post(`clinic/invites/${id}`, params, null, false)
  }
}
