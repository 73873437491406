import BaseRequest from '../BaseRequest'

export default class AdminRequest extends BaseRequest {
  getKyc (params) {
    return this.get('admin/doctor-kyc', params)
  }
  getKycDetail (id) {
    return this.get(`admin/doctor-kyc/${id}`)
  }
  putKycDetailJoin (id) {
    return this.put(`admin/doctor-kyc/${id}/join`)
  }
  putKycDetailStatus (id, params) {
    return this.put(`admin/doctor-kyc/${id}/status`, params)
  }
  getClinics (params) {
    return this.get('admin/clinics', params)
  }

  getClinicDetail (id) {
    return this.get('admin/clinics/' + id)
  }

  postClinic (data) {
    return this.post('admin/clinics', data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  postClinicUpdate (id, data) {
    return this.post('admin/clinics/' + id, data)
  }

  deleteClinic (id) {
    return this.post(`admin/clinics/${id}/delete`)
  }

  loginClinic (id) {
    return this.post(`admin/clinics/${id}/login`)
  }

  getDoctors (params) {
    return this.get('admin/doctors', params)
  }

  loginDoctor (id) {
    return this.post(`admin/doctors/${id}/login`)
  }

  getNotifications (params) {
    return this.get(`admin/notifications`, params)
  }

  getNewNotifications (params) {
    return this.get(`admin/notification/unread`, params)
  }
  addTemplate (data) {
    return this.post(`admin/templates`, data, {
      'Content-Type': 'multipart/form-data'
    })
  }

  removeTemplate (id) {
    return this.post(`admin/templates/${id}/delete`)
  }

  getClinicApplies (params) {
    return this.get(`admin/applies`, params)
  }

  acceptClinicApplies (id, params) {
    return this.post(`admin/applies/${id}/accept`, params)
  }

  rejectClinicApplies (id, params) {
    return this.post(`admin/applies/${id}/reject`, params)
  }

  getStats (params) {
    return this.get(`admin/stats`, params)
  }

  getUser (params) {
    return this.get(`admin/users`, params)
  }
  welcomePassword (params) {
    return this.post('cs/v1/send-verify-doctor-to-ws', params)
  }
  postResetPassword (id, params) {
    return this.post(`admin/users/${id}/password`, params)
  }
  getRequest (params) {
    return this.get(`admin/requests`, params)
  }
  sendMessage (params) {
    return this.post(`admin/notifications/send-campaign`, params)
  }
  getQuestions (params) {
    return this.get(`admin/questions`, params)
  }
  postQuestions (params) {
    return this.post(`admin/questions`, params)
  }
  postQuestionsEdit (id, params) {
    return this.post(`admin/questions/${id}`, params)
  }
  postDoctorActive (id, params) {
    return this.post(`admin/doctors/${id}/status`, params)
  }
  getRequestApptPending (params) {
    return this.get(`admin/requests?type=appt-pending`, params)
  }
  getRequestInvoiceUnpaid (params) {
    return this.get(`admin/requests?type=invoice-unpaid`, params)
  }
  getRequestCalling (params) {
    return this.get(`admin/requests?type=calling`, params)
  }
  getRequestCallCompleted (params) {
    return this.get(`admin/requests?type=call-completed`, params)
  }
  getRequestCallFailed (params) {
    return this.get(`admin/request?type=call-failed`, params)
  }
  getRequestCallNotJoin (params) {
    return this.get(`admin/requests?type=call-not-join`, params)
  }
  getRequestApptChanged (params) {
    return this.get(`admin/requests?type=appt-changed`, params)
  }
  getRequestPatientCancelled (params) {
    return this.get(`admin/requests?type=patient-cancelled`, params)
  }
  getRequestDoctorRejected (params) {
    return this.get(`admin/requests?type=doctor-rejected`, params)
  }
  getInvoiceListing (params) {
    return this.get(`admin/invoices`, params)
  }
  postBankAccounts (params) {
    return this.post(`admin/bank-accounts`, params)
  }
  getBankAccounts () {
    return this.get(`admin/bank-accounts`)
  }
  getBankAccountsById (params) {
    return this.get(`admin/bank-accounts`, params)
  }
  postBankAccountsUpdate (id, params) {
    return this.post(`admin/bank-accounts/${id}`, params)
  }
  postBankAccountsDelete (id) {
    return this.post(`admin/bank-accounts/${id}/delete`)
  }
  postInvoicesStatus (id, params) {
    return this.post(`admin/invoices/${id}/status`, params)
  }
  postInvoiceNotifyUnpaid (id) {
    return this.post(`admin/invoices/${id}/notify-unpaid`)
  }
  getPersons (params) {
    return this.get(`admin/persons`, params)
  }
  getPersonByID (id) {
    return this.get(`admin/persons/${id}`)
  }
  getPersonsNotes (params) {
    return this.get(`admin/person-notes`, params)
  }
  postPersonsNotes (params) {
    return this.post(`admin/person-notes`, params)
  }
  postPersonsNotesEdit (id, params) {
    return this.post(`admin/person-notes/${id}`, params)
  }
  getWorkspacePermissions (params) {
    return this.get(`admin/workspace-permissions`, params)
  }
  getQuestionGroups (params) {
    return this.get(`admin/question-groups`, params)
  }
  postQuestionGroups (params) {
    return this.post(`admin/question-groups`, params)
  }
  postQuestionGroupsUpdate (id, params) {
    return this.post(`admin/question-groups/${id}`, params)
  }
  postQuestionGroupsDelete (id) {
    return this.post(`admin/question-groups/${id}/delete`)
  }
  getAppt (params) {
    return this.get(`admin/appointments`, params)
  }
  getPersonCases (id, params) {
    return this.get(`admin/persons/${id}/cases`, params)
  }
  getDiseases (params) {
    return this.get(`admin/diseases`, params)
  }
  postDiseases (params) {
    return this.post(`admin/diseases`, params)
  }
  getDiseasesId (id, params) {
    return this.get(`admin/diseases/${id}`, params)
  }
  postDiseasesId (id, params) {
    return this.post(`admin/diseases/${id}`, params)
  }
  postDiseasesDelete (id) {
    return this.delete(`admin/diseases/${id}`)
  }
  getSymptomCategory (params) {
    return this.get(`admin/symptom-category`, params)
  }
  postSymptomCategory (params) {
    return this.post(`admin/symptom-category`, params)
  }
  getSymptomCategoryId (id, params) {
    return this.get(`admin/symptom-category/${id}`, params)
  }
  postSymptomCategoryId (id, params) {
    return this.post(`admin/symptom-category/${id}`, params)
  }
  postSymptomCategoryDelete (id) {
    return this.delete(`admin/symptom-category/${id}`)
  }
  postTags (params) {
    return this.post(`admin/tags`, params)
  }
  postTagsId (id, params) {
    return this.post(`admin/tags/${id}`, params)
  }
  postTagsDelete (id) {
    return this.post(`admin/tags/${id}/delete`)
  }
  postMedicines (params) {
    return this.post(`admin/medicines`, params)
  }
  postMedicineId (id, params) {
    return this.post(`admin/medicines/${id}`, params)
  }
  postMedicineDelete (id) {
    return this.post(`admin/medicines/${id}/delete`)
  }
  getPersonsDocuments (person_id, params) {
    return this.get(`admin/persons/${person_id}/documents`, params)
  }
  getPersonsDiseases (params) {
    return this.get(`admin/person-diseases`, params)
  }
  postPersonsDiseasesStart (id) {
    return this.put(`admin/person-diseases/${id}/start`)
  }
  getPersonsDiseasesById (id) {
    return this.get(`admin/person-diseases/${id}`)
  }
  putPersonsDiseasesById (id, params) {
    return this.put(`admin/person-diseases/${id}`, params)
  }
  getPersonsCareplanSign (params) {
    return this.get(`admin/careplan-sign`, params)
  }
  getPersonTestResult (params) {
    return this.get(`admin/person-test-result`, params)
  }
  getPersonTestResultByID (id) {
    return this.get(`admin/person-test-result/${id}`)
  }
  postPersonTestResult (params) {
    return this.post(`admin/person-test-result`, params)
  }
  postPersonTestMulti (params) {
    return this.post(`admin/person-test/multi`, params)
  }
  getPersonDiseasesByIdPersonTest (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/person-test`, params)
  }
  postPersonExplorationMulti (params) {
    return this.post(`admin/person-exploration/multi`, params)
  }
  getPersonDiseasesExploration (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/person-exploration`, params)
  }
  getMedicines (params) {
    return this.get(`admin/person-medicines`, params)
  }
  postPersonMedicines (params) {
    return this.post(`admin/person-medicines`, params)
  }
  postPersonMedicinesMonitor (id) {
    return this.post(`admin/person-medicines/${id}/monitor`)
  }
  getMedicineSchedules (id, params) {
    return this.get(`admin/person-diseases/${id}/medicine-schedules`, params)
  }
  getPersonDiseaseNotes (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/person-disease-notes`, params)
  }
  postPersonDiseaseNotes (person_diseases_id, params) {
    return this.post(`admin/person-diseases/${person_diseases_id}/person-disease-notes`, params)
  }
  getPersonDiseaseNotesByID (id) {
    return this.get(`admin/person-disease-notes/${id}`)
  }
  putPersonDiseaseNotes (id, params) {
    return this.put(`admin/person-disease-notes/${id}`, params)
  }
  deletePersonDiseaseNotes (id) {
    return this.delete(`admin/person-disease-notes/${id}`)
  }
  getOrders (params) {
    return this.get(`admin/orders`, params)
  }
  getOrdersByID (id, params) {
    return this.get(`admin/orders/${id}`, params)
  }
  putOrderStatus (id, params) {
    return this.put(`admin/orders/${id}`, params)
  }
  getTopic (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/topics`, params)
  }
  getTopicById (topic_id, params) {
    return this.get(`admin/topic/${topic_id}/comments`, params)
  }
  getOrderNotes (params) {
    return this.get(`admin/order-notes`, params)
  }
  getOrderNotesByID (topic_id) {
    return this.get(`admin/order-notes/${topic_id}`)
  }
  postOrderNotes (params) {
    return this.post(`admin/order-notes`, params)
  }
  getTopicId (id, params) {
    return this.get(`admin/topics/${id}/show`, params)
  }
  getServices (params) {
    return this.get(`admin/services`, params)
  }
  getSurvivalStatSetting (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/setting-monitor-survival`, params)
  }
  getPersonSymptomsValue (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/person-symptoms-value`, params)
  }
  postServices (params) {
    return this.post(`admin/services`, params)
  }
  postServicesUpdate (id, params) {
    return this.post(`admin/services/${id}`, params)
  }
  getNews (params) {
    return this.get(`admin/news`, params)
  }
  postNews (params) {
    return this.post(`admin/news`, params)
  }
  postNewsUpdate (id, params) {
    return this.post(`admin/news/${id}`, params)
  }
  getPersonsDiseasesHistories (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/histories`, params)
  }
  putPersonsCareplanSign (id, params) {
    return this.put(`admin/careplan-sign/${id}`, params)
  }
  multiStartMultiMeds (params) {
    return this.post(`admin/multi-start`, params)
  }
  getServiceCategory (params) {
    return this.get(`admin/service-category`, params)
  }
  createServiceCategory (params) {
    return this.post(`admin/service-category`, params)
  }
  showServiceCategory (id, params) {
    return this.get(`admin/service-category/${id}/show`, params)
  }
  updateServiceCategory (id, params) {
    return this.post(`admin/service-category/${id}/update`, params)
  }
  deleteServiceCategory (id, params) {
    return this.post(`admin/service-category/${id}/delete`, params)
  }
  getNewCategory (params) {
    return this.get(`admin/new-categories`, params)
  }
  createNewCategory (params) {
    return this.post(`admin/new-categories`, params)
  }
  showNewCategory (id, params) {
    return this.get(`admin/new-categories/${id}`, params)
  }
  updateNewCategory (id, params) {
    return this.put(`admin/new-categories/${id}`, params)
  }
  getAdvices (person_diseases_id, params) {
    return this.get(`admin/person-diseases/${person_diseases_id}/advice`, params)
  }
  getDiseasesTemplates (params) {
    return this.get(`admin/diseases-templates`, params)
  }
  createDiseasesTemplates (params) {
    return this.post(`admin/diseases-templates`, params)
  }
  getTemplateDetail (id) {
    return this.get(`admin/diseases-templates/${id}/show`)
  }
  multiCreateDiseasesTemplateSymtomps (id, data) {
    return this.post(`admin/diseases-templates/${id}/multi-add-symptoms`, data)
  }
  multiCreateDiseasesTemplateMonitorStats (id, data) {
    return this.post(`admin/diseases-templates/${id}/multi-add-stats`, data)
  }
  updateDiseasesTemplates (id, data) {
    return this.post(`admin/diseases-templates/${id}/update`, data)
  }
  changeStatusDiseasesTemplates (id) {
    return this.post(`admin/diseases-templates/${id}/active-inactive`)
  }
  getSurvivalStats (params) {
    return this.get(`admin/survival-stats`, params)
  }
  createSurvivalStats (params) {
    return this.post(`admin/survival-stats`, params)
  }
  updateSurvivalStats (id, params) {
    return this.post(`admin/survival-stats/${id}/update`, params)
  }
  deleteSurvivalStats (id) {
    return this.post(`admin/survival-stats/${id}/delete`)
  }
  updateSurvivalStatStatus (id) {
    return this.post(`admin/survival-stats/${id}/status`)
  }
  getPackages (params) {
    return this.get(`admin/v3/member-packages`, params)
  }
  createPackages (params) {
    return this.post(`admin/v3/member-packages`, params)
  }
  showPackages (id) {
    return this.get(`admin/v3/member-packages/${id}`)
  }
  updatePackages (id, params) {
    return this.post(`admin/v3/member-packages/${id}/update`, params)
  }
  getPackageOrders (params) {
    return this.get(`admin/v3/member-package-orders`, params)
  }
  getPackageOrderNotes (order_id, params) {
    return this.get(`admin/member-package-order/${order_id}/notes`, params)
  }
  createPackageOrderNotes (order_id, params) {
    return this.post(`admin/member-package-order/${order_id}/notes`, params)
  }
  changeStatusPackageOrder (params) {
    return this.post(`admin/v3/member-package-orders/change-status`, params)
  }
  getCallLogs (params) {
    return this.get(`admin/call-logs`, params)
  }
  getPacs (params) {
    return this.get(`admin/pacs-dicoms`, params)
  }
  getUrlPac (params) {
    return this.get(`admin/pacs-dicoms/get-url`, params)
  }
  createPacs (params) {
    return this.post(`admin/pacs-dicoms`, params)
  }
  getComboPackages (params) {
    return this.get(`admin/v3/member-package-combo`, params)
  }
  createComboPackages (params) {
    return this.post(`admin/v3/member-package-combo`, params)
  }
  showComboPackages (id) {
    return this.get(`admin/v3/member-package-combo/${id}`)
  }
  updateComboPackages (id, params) {
    return this.post(`admin/v3/member-package-combo/${id}/update`, params)
  }
  getPackageBenefits (params) {
    return this.get(`admin/v3/member-package-benefits`, params)
  }
  createPackagesBenefit (params) {
    return this.post(`admin/v3/member-package-benefits`, params)
  }
  getVoucher (params) {
    return this.get(`admin/v3/vouchers`, params)
  }
  createVoucher (params) {
    return this.post(`admin/v3/vouchers`, params)
  }
  updateVoucher (id, params) {
    return this.post(`admin/v3/vouchers/${id}/update`, params)
  }
  // deleteBenefit (id) {
  //   return this.post(`admin/v3/member-package-benefits/${id}/delete`)
  // }
  getHodoMember (params) {
    return this.get(`admin/payment-transactions`, params)
  }
  changeWsFeature (params) {
    return this.post(`admin/workspace-settings`, params)
  }
  getWsFeature (params) {
    return this.get(`admin/workspace-settings`, params)
  }
  getCheckpoints (params) {
    return this.get(`admin/mr-checkpoints/get`, params)
  }
  postCheckpoints (params) {
    return this.post(`admin/mr-checkpoints/create`, params)
  }
  updateCheckpoints (id, params) {
    return this.put(`admin/mr-checkpoints/${id}/update`, params, null, false)
  }
  multipleUpdateCheckpoints (params) {
    return this.post(`admin/mr-checkpoints/multi-update`, params)
  }
  multipleUpdateChecklists (params) {
    return this.post(`admin/mr-checklists/multi-update`, params)
  }
  postChecklists (params) {
    return this.post(`admin/mr-checklists/create`, params)
  }
  updateChecklists (id, params) {
    return this.put(`admin/mr-checklists/${id}/update`, params)
  }
  multipleUpdateChecklistItem (params) {
    return this.post(`admin/mr-checklists-status/multi-update`, params)
  }
  postChecklistItem (params) {
    return this.post(`admin/mr-checklists-status/create`, params)
  }
  updateChecklistItem (id, params) {
    return this.put(`admin/mr-checklists-status/${id}/update`, params)
  }
  getWorkspaceSettings (params) {
    return this.get(`go/admin/workspace-features`, params)
  }
  updateWorkspaceSettings (params) {
    return this.post(`go/admin/workspace-features`, params)
  }
}
