import VimesBaseRequest from '../VimesBaseRequest'

export default class VimesSyncRequest extends VimesBaseRequest {
  vimesSyncData (data) {
    return this.post(`v1/sync-vimes`, data, {
      timeout: 600000
    })
  }
  vimesSyncDataSkb (data) {
    return this.post(`v1/sync2hp`, data, {
      timeout: 600000
    })
  }
}
