import BaseRequest from '../BaseRequest'

export default class QAndARequest extends BaseRequest {
  fetchListTopic (params) {
    return this.get(`go/circle/qa/topic`, params)
  }
  fetchListTag (params) {
    return this.get(`go/circle/qa/tag`, params)
  }
  fetchListBookmark (params) {
    return this.get(`go/circle/qa/bookmark`, params)
  }
  addBookmark (params) {
    return this.post(`go/circle/qa/bookmark`, params)
  }
  createTag (params) {
    return this.post(`go/circle/qa/tag`, params)
  }
  createTopic (params) {
    return this.post(`go/circle/qa/topic`, params)
  }
  updateTopic (id, params) {
    return this.put(`go/circle/qa/topic/${id}`, params)
  }
  fetchTopicByID (id) {
    return this.get(`go/circle/qa/topic/${id}`)
  }
  removeBookmark (id) {
    return this.delete(`go/circle/qa/bookmark/${id}`)
  }
  addVote (params) {
    return this.post(`go/circle/qa/vote`, params)
  }
  removeVote (id) {
    return this.delete(`go/circle/qa/vote/${id}`)
  }
  deleteTopic (id) {
    return this.delete(`go/circle/qa/topic/${id}`)
  }
}
