import axios from 'axios'
import appUtils from '../utils/appUtils'

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_SIGN_SEVER_API}api/`
  // timeout: 300000
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = appUtils.getLocalToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosInstance
