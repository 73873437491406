import appUtils from '@/utils/appUtils'
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API}api/`
  // timeout: 300000
})

axiosInstance.interceptors.request.use(
  (config) => {
    const token = appUtils.getLocalToken()
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// axiosInstance.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     const originalConfig = err.config;

//     if (originalConfig.url !== "/login" && err.response) {
//       // Access Token was expired
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;

//         try {
//           const rs = await axiosInstance.post("/auth/refreshtoken", {
//             refreshToken: appUtils.getLocalRefreshToken(),
//           });

//           const { accessToken } = rs.data;

//           // store.dispatch("auth/refreshToken", accessToken);
//           appUtils.updateLocalAccessToken(accessToken);

//           return axiosInstance(originalConfig);
//         } catch (_error) {
//           return Promise.reject(_error);
//         }
//       }
//     }

//     return Promise.reject(err);
//   }
// );

export default axiosInstance
